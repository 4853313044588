import EntityStateActionTypeEnum from '../../../../../constants/enums/EntityStateActionTypeEnum';
import type { BaseEntityListItemApiModel } from '../../../../../models/api/base/BaseEntityListItemApiModel';
import type { ItemsListModel } from '../../../../../models/system/pagination/ItemsListModel';
import type { FSAP } from '../../../base/fsa';
import type { BaseItemsSucceededPayload } from '../../../models/payloads/base/BaseItemsSucceededPayload';
import createEntitiesListActionName from './createEntitiesListActionName';

const createEntitiesListPopulateAction = <TEntityListItemModel extends BaseEntityListItemApiModel>(
  entityType: string, // EntityTypeEnum,
  data: ItemsListModel<TEntityListItemModel>,
  namespace: string
): FSAP<BaseItemsSucceededPayload<TEntityListItemModel>> => ({
  type: createEntitiesListActionName(entityType, EntityStateActionTypeEnum.Succeeded, namespace),
  payload: {
    data,
  },
});

export default createEntitiesListPopulateAction;
