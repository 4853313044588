import BaseEntitiesQueryMapper from '../../../framework/base/common/helpers/mappers/base/BaseEntitiesQueryMapper';
import type { BaseEntitiesApiQuery } from '../../../framework/base/common/models/queries/base/BaseEntitiesApiQuery';
import type { BaseEntitiesQuery } from '../../../framework/base/common/models/queries/base/BaseEntitiesQuery';
import type { AdvertsApiQueryFilters } from '../../models/entities/advert/queries/AdvertsApiQueryFilters';
import type { AdvertsQueryFilters } from '../../models/entities/advert/queries/AdvertsQueryFilters';

class AdvertsQueryMapper extends BaseEntitiesQueryMapper<
  AdvertsQueryFilters,
  BaseEntitiesQuery<AdvertsQueryFilters>,
  AdvertsApiQueryFilters,
  BaseEntitiesApiQuery<AdvertsApiQueryFilters>
> {

  public override mapQueryToApiQuery(query: BaseEntitiesQuery<AdvertsQueryFilters>): BaseEntitiesApiQuery<AdvertsApiQueryFilters> {
    const apiQuery = super.mapQueryToApiQueryIntrinsic(query);

    return apiQuery;
  }

  public override mapQueryFiltersToApiQueryFilters(queryFilters: AdvertsQueryFilters): AdvertsApiQueryFilters {
    const apiQueryFilters: AdvertsApiQueryFilters = super.mapQueryFiltersToApiQueryFiltersIntrinsic(queryFilters);

    apiQueryFilters.category = queryFilters.category;

    return apiQueryFilters;
  }
}

export default AdvertsQueryMapper;
