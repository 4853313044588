import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { IUrlGenerator } from '../../../../../../helpers/IUrlGenerator';
import type { IEntitiesUrlQueryMapper } from '../../../../../../helpers/mappers/interfaces/IEntitiesUrlQueryMapper';
import useJwt from '../../../../../../hooks/useJwt';
import type { BaseEntityListItemApiModel } from '../../../../../../models/api/base/BaseEntityListItemApiModel';
import type { BaseEntitiesQuery } from '../../../../../../models/queries/base/BaseEntitiesQuery';
import createEntitiesListClearAction from '../../../../../../store/actions/factories/entity/items/createEntitiesListClearAction';
import createUsersEntitiesListRequestAction from '../../../../../../store/actions/factories/entity/items/createUsersEntitiesListRequestAction';
import { setApplicationErrorMessage } from '../../../../../../store/modules/app/notificationsReducer';
import type { EntitiesListSelector } from '../../../../../../store/selectors/EntitiesListSelector';

interface EntitiesListFromStateReloadRef {
  forceReloadFromState: () => void;
}

interface UseEntitiesListFromStateParams<
  TModel extends BaseEntityListItemApiModel,
  TQuery extends BaseEntitiesQuery,
  TState
> {
  urlGenerator: IUrlGenerator<TQuery, IEntitiesUrlQueryMapper<TQuery>>,
  entityType: string,
  selector: EntitiesListSelector<TModel, TQuery, TState>,
  namespace: string,
  modifyQuery: ((query: TQuery) => TQuery) | undefined,
  innerRef: React.Ref<EntitiesListFromStateReloadRef> | undefined;
}

interface UseEntitiesListFromStateResult<
  TModel extends BaseEntityListItemApiModel
> {
  data: TModel[] | undefined;
  count: number;
  isLoading: boolean;
  reload: () => void;
  forceReload: () => void;
}

const isQueriesDifferent = <TQuery extends BaseEntitiesQuery>(
  stateQuery: TQuery | undefined,
  urlQuery: TQuery,
): boolean => {
  const stringifiedUrlQuery = JSON.stringify(urlQuery);
  const stringifiedStateQuery = JSON.stringify(stateQuery);

  return stringifiedUrlQuery !== stringifiedStateQuery;
};

// let renderCounter = 0;

const useEntitiesListFromState = <
  TModel extends BaseEntityListItemApiModel,
  TQuery extends BaseEntitiesQuery,
  TState,
>(
  { urlGenerator, entityType, selector, namespace, modifyQuery, innerRef }: UseEntitiesListFromStateParams<TModel, TQuery, TState>,
) => {

  // console.debug(`Render useEntitiesListFromState ${++renderCounter}`);

  const data = useSelector(selector.data);
  const count = useSelector(selector.getCount);
  const isLoading = useSelector(selector.loading);
  const query = useSelector(selector.query);
  const error = useSelector(selector.error);

  const jwt = useJwt();

  const dispatch = useDispatch();

  const reload = useCallback((force = false) => {
    const urlQuery = modifyQuery ? modifyQuery(urlGenerator.query) : urlGenerator.query;

    if (force || isQueriesDifferent(query, urlQuery)) {
      dispatch(createUsersEntitiesListRequestAction(jwt ?? '', entityType, urlQuery, namespace));
    }
  }, [dispatch, entityType, jwt, modifyQuery, namespace, query, urlGenerator.query]);

  const forceReloadFromState = useCallback(() => {
    reload(true);
  }, [reload]);

  useEffect(() => {
    if (error) {
      dispatch(setApplicationErrorMessage());
    }
  }, [dispatch, error]);

  useEffect(() => () => {
    dispatch(createEntitiesListClearAction(entityType, namespace));
  }, [dispatch, entityType, namespace]);

  React.useImperativeHandle<EntitiesListFromStateReloadRef, EntitiesListFromStateReloadRef>(innerRef, (): EntitiesListFromStateReloadRef => {
    const result: EntitiesListFromStateReloadRef = {
      forceReloadFromState: forceReloadFromState,
    };

    return result;
  });

  const result: UseEntitiesListFromStateResult<TModel> = {
    data,
    count,
    isLoading,
    reload,
    forceReload: forceReloadFromState,
  };

  return result;
};

export type {
  EntitiesListFromStateReloadRef
};

export default useEntitiesListFromState;
