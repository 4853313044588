import BaseAppCommonConstants from '../../framework/base/common/constants/BaseAppCommonConstants';

class AppCommonConstants extends BaseAppCommonConstants {

  public static override getInstance(): AppCommonConstants {
    return BaseAppCommonConstants.getInstance();
  }

  public static setup(): void {
    this.instance = new AppCommonConstants(20);
  }
}

export default AppCommonConstants;
