import React from 'react';
import { useParams } from 'react-router-dom';
import DynamicModuleLoaderWrapper from '../../../framework/base/common/components/shared/elements/DynamicModuleLoaderWrapper';
import EntityDetailFromState from '../../../framework/base/common/modules/shared/components/pages/entities/EntityDetailFromState';
import createEntityDetailSelector from '../../../framework/base/common/store/selectors/helpers/createEntityDetailSelector';
import EntityTypeEnum from '../../constants/enums/EntityTypeEnum';
import StoreNamespaceEnum from '../../constants/enums/StoreNamespaceEnum';
import type { AdvertDetailApiModel } from '../../models/api/advert/AdvertDetailApiModel';
import advertsModule from '../../store/modules/adverts/advertsModule';
import type { IAdvertsAwareState } from '../../store/modules/adverts/models/IAdvertsAwareState';
import AdvertDetailModalImages from './AdvertDetailModalImages';

const AdvertDetailModal: React.FC = () => {

  const { id } = useParams<{ id: string; }>();

  return (
    <DynamicModuleLoaderWrapper modules={[advertsModule]}>
      <main className='container position-relative pt-3'>
        <EntityDetailFromState
          entityType={EntityTypeEnum.Advert}
          selector={createEntityDetailSelector<AdvertDetailApiModel, IAdvertsAwareState>(state => state.adverts.detail)}
          namespace={StoreNamespaceEnum.Catalog}
          query={{ filters: { id } }}
          render={(item) => {
            return (
              <article className='row pt-4'>
                <div className='col-12 col-lg-4'>
                  <header>
                    <h1 className='h4'>{item.title}</h1>
                    <h2 style={{ marginTop: '-0.5em', fontSize: '1.1em', fontWeight: 'normal' }}>Anna Michałowska</h2>
                  </header>
                  {/* {AdvertContentService.getLongInfo(item).length > 0 && (
                    <h3 className='py-2' style={{ fontSize: '1em', fontWeight: 'normal' }}>
                      {AdvertContentService.getLongInfo(item).join(', ')}
                    </h3>
                  )} */}
                  {item.description && (
                    <p>{item.description}</p>
                  )}
                </div>
                {item.images && (
                  <section className='col'>
                    <AdvertDetailModalImages
                      item={item}
                      images={item.images}
                    />
                  </section>
                )}
              </article>
            );
          }} />
      </main>
    </DynamicModuleLoaderWrapper>
  );
};

export default AdvertDetailModal;
