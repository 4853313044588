import React from 'react';
import LazyLoad from 'react-lazy-load';
import EntityImageSizeEnum from '../../../../../framework/base/common/constants/enums/EntityImageSizeEnum';
import type { ImageModel } from '../../../../../framework/base/common/models/entities/image/base/ImageModel';
import AdvertPathHelper from '../../../../helpers/AdvertPathHelper';
import type { AdvertSharedPropertiesApiModel } from '../../../../models/api/advert/AdvertSharedPropertiesApiModel';
import AdvertDetailPageContentService from '../../../../services/content/advert/AdvertDetailPageContentService';

interface AdvertsListItemImageProps {
  advert: AdvertSharedPropertiesApiModel;
  image?: ImageModel | undefined;
  src?: string;
  forceDisplay?: boolean | undefined;
  onImageLoaded?: () => void;
}

const AdvertsListItemImage: React.FC<AdvertsListItemImageProps> = ({
  advert,
  src,
  forceDisplay,
  image,
  onImageLoaded,
}: AdvertsListItemImageProps) => {

  const handleOnLoad = (): void => {
    if (onImageLoaded) {
      onImageLoaded();
    }
  };

  return !image
    ? null
    : (
      <picture>
        {forceDisplay && (
          <img
            src={src ?? AdvertPathHelper.getAdvertImageUrl(advert.id, image, EntityImageSizeEnum.Small300)}
            alt={AdvertDetailPageContentService.getAdvertTitle(advert)}
            style={{
              width: '100%',
            }}
            onLoad={handleOnLoad}
          />
        )}
        {!forceDisplay && (
          <LazyLoad>
            <img
              src={src ?? AdvertPathHelper.getAdvertImageUrl(advert.id, image, EntityImageSizeEnum.Small300)}
              alt={AdvertDetailPageContentService.getAdvertTitle(advert)}
              style={{
                width: '100%',
              }}
              onLoad={handleOnLoad}
            />
          </LazyLoad>
        )}
      </picture>
    );
};

export default AdvertsListItemImage;
