import type { BaseEntityDetailApiModel } from '../../../common/models/api/base/BaseEntityDetailApiModel';
import type { IEntityDetailService } from '../../../common/services/interfaces/IEntityDetailService';
import BaseService from './BaseService';

abstract class BaseEntitiesService<
  TDetailApiModel extends BaseEntityDetailApiModel,
> extends BaseService
  implements IEntityDetailService<TDetailApiModel> {

  constructor(routePath: string, jwt?: string) {
    super(routePath, jwt);
  }

  public async getItem(id: string): Promise<TDetailApiModel> {
    return this.sendGet<TDetailApiModel>(`/${id}`);
  }

  public async delete(id: string): Promise<void> {
    return this.sendDelete(`/${id}`);
  }
}

export default BaseEntitiesService;
