import type EntityImageSizeEnum from '../../framework/base/common/constants/enums/EntityImageSizeEnum';
import type EntityImageThumbnailSizeEnum from '../../framework/base/common/constants/enums/EntityImageThumbnailSizeEnum';
import PathHelper from '../../framework/base/common/helpers/PathHelper';
import type { ImageModel } from '../../framework/base/common/models/entities/image/base/ImageModel';
import RendererRoutePaths from '../constants/path/RendererRoutePaths';

class AdvertPathHelper {

  public static getAdvertImageUrl(advertId: string, image: ImageModel, size: EntityImageSizeEnum, webp = true): string {
    return PathHelper.getEntityImageUrl(RendererRoutePaths.IMAGES_ADVERT, advertId, image, size, webp);
  }

  public static getAdvertImageThumbnailUrl(advertId: string, size: EntityImageThumbnailSizeEnum, image?: ImageModel): string {
    return PathHelper.getEntityImageThumbnailUrl(RendererRoutePaths.IMAGES_ADVERT, advertId, size, image);
  }
}

export default AdvertPathHelper;
