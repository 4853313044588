import React, { useEffect, useMemo, type ReactNode, type RefObject } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../../../../components/shared/elements/Loading';
import Pagination from '../../../../../components/shared/elements/Pagination';
import Sorting from '../../../../../components/shared/elements/Sorting';
import { PAGINATION_SORT_BY_CREATED_AT_DESC, PAGINATION_SORT_BY_UPDATED_AT } from '../../../../../constants/BaseCommonConstants';
import type { IUrlGenerator } from '../../../../../helpers/IUrlGenerator';
import type { IEntitiesUrlQueryMapper } from '../../../../../helpers/mappers/interfaces/IEntitiesUrlQueryMapper';
import type { BaseEntityListItemApiModel } from '../../../../../models/api/base/BaseEntityListItemApiModel';
import type { BaseEntitiesQuery } from '../../../../../models/queries/base/BaseEntitiesQuery';
import type { BaseEntitiesQueryFilters } from '../../../../../models/queries/base/BaseEntitiesQueryFilters';
import type { IEntitiesListService } from '../../../../../services/interfaces/IEntitiesListService';

interface EntitiesListHelpers<
  TService extends IEntitiesListService<TListItemModel>,
  TListItemModel extends BaseEntityListItemApiModel,
> {
  service: TService;
}

interface SharedEntitiesListProps<
  TQuery extends BaseEntitiesQuery<TQueryFilters>,
  TQueryFilters extends BaseEntitiesQueryFilters = BaseEntitiesQueryFilters,
> {
  urlGenerator: IUrlGenerator<TQuery, IEntitiesUrlQueryMapper<TQuery>, TQueryFilters>;
  isFiltersUsedForQuerying?: boolean;
  content?: {
    noEntities?: string;
    noEntitiesCreateEntityButton?: {
      label: string;
      urlPathname: string;
      urlSearch: string | undefined;
    };
    noEntitiesForSearch?: string;
    subheader?: string;
  } | undefined,
  sorts?: Record<string, string> | undefined;
  onlySorts?: Record<string, string> | undefined;
  sizes?: number[] | undefined;
  defaultPaginationSize?: number | undefined;
  paginationScrollToRef?: RefObject<HTMLElement> | undefined,
}

interface EntitiesListProps<
  TService extends IEntitiesListService<TListItemModel>,
  TListItemModel extends BaseEntityListItemApiModel,
  TQuery extends BaseEntitiesQuery<TQueryFilters>,
  TQueryFilters extends BaseEntitiesQueryFilters = BaseEntitiesQueryFilters,
> extends SharedEntitiesListProps<
  TQuery,
  TQueryFilters
> {
  items?: TListItemModel[] | undefined;
  count?: number | undefined;
  isLoading?: boolean | undefined;
  service: TService;
  reload: () => void;
  children: (items: TListItemModel[], helpers: EntitiesListHelpers<TService, TListItemModel>) => ReactNode;
}

const EntitiesList: <
  TService extends IEntitiesListService<TListItemModel>,
  TListItemModel extends BaseEntityListItemApiModel,
  TQuery extends BaseEntitiesQuery<TQueryFilters>,
  TQueryFilters extends BaseEntitiesQueryFilters = BaseEntitiesQueryFilters,
>(
  props: EntitiesListProps<TService, TListItemModel, TQuery, TQueryFilters>
) => React.ReactElement<
  EntitiesListProps<TService, TListItemModel, TQuery, TQueryFilters>
> = <
  TIntrinsicService extends IEntitiesListService<TIntrinsicListItemModel>,
  TIntrinsicListItemModel extends BaseEntityListItemApiModel,
  TIntrinsicQuery extends BaseEntitiesQuery<TIntrinsicQueryFilters>,
  TIntrinsicQueryFilters extends BaseEntitiesQueryFilters = BaseEntitiesQueryFilters,
>({
  items,
  count,
  isLoading,
  service,
  urlGenerator,
  content,
  sorts,
  onlySorts,
  sizes,
  isFiltersUsedForQuerying,
  defaultPaginationSize,
  paginationScrollToRef,
  reload,
  children,
}: EntitiesListProps<
  TIntrinsicService,
  TIntrinsicListItemModel,
  TIntrinsicQuery,
  TIntrinsicQueryFilters
>): React.ReactElement => {

    const helpers = useMemo<EntitiesListHelpers<TIntrinsicService, TIntrinsicListItemModel>>(() => ({
      service: service,
    }), [service]);

    const sortingRef = React.createRef<HTMLElement>();

    useEffect(() => {
      reload();
    }, [reload]);

    const handleOnPaginationClick = () => {
      const ref = paginationScrollToRef?.current ?? sortingRef.current;

      if (ref) {
        ref.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    };

    sorts = onlySorts
      ? onlySorts
      : {
        '': 'Najnowsze',
        [PAGINATION_SORT_BY_CREATED_AT_DESC]: 'Najstarsze',
        [PAGINATION_SORT_BY_UPDATED_AT]: 'Ostatnio edytowane',
        ...sorts,
      };

    isFiltersUsedForQuerying = isFiltersUsedForQuerying !== undefined
      ? isFiltersUsedForQuerying
      : !!urlGenerator.query.filters;

    return (
      <Loading isLoading={isLoading}>
        {(): React.ReactNode => items && count != undefined
          ? (
            <>
              {items.length === 0 && (
                <>
                  {!isFiltersUsedForQuerying && content?.noEntities && (
                    <>
                      <h3 className='h2 fw-light py-5 text-center'>
                        {content.noEntities}
                      </h3>
                      {content?.noEntitiesCreateEntityButton && (
                        <div className='text-center p-4 mb-5'>
                          <Link
                            to={{
                              pathname: content.noEntitiesCreateEntityButton.urlPathname,
                              search: content.noEntitiesCreateEntityButton.urlSearch ?? '',
                            }}
                            className='btn btn-lg btn-primary'
                          >
                            {content.noEntitiesCreateEntityButton.label}
                          </Link>
                        </div>
                      )}
                    </>
                  )}
                  {isFiltersUsedForQuerying && content?.noEntitiesForSearch && (
                    <div className='text-center p-4 mb-5 fw-light py-5'>
                      <h3 className='h2 text-center fw-light'>
                        {content.noEntitiesForSearch}
                      </h3>
                      <Link
                        to={urlGenerator.generateUrl(((draft) => ({
                          ...draft,
                          filters: undefined,
                          pagination: undefined,
                        })))}
                      >
                        Odznacz wszystkie filtry
                      </Link>
                      .
                    </div>
                  )}
                </>
              )}
              {items.length > 0 && (
                <>
                  {content?.subheader && (
                    <h2 className='h4 fw-normal'>
                      {content.subheader}
                    </h2>
                  )}
                  <Sorting
                    sorts={sorts}
                    sizes={sizes}
                    defaultPaginationSize={defaultPaginationSize}
                    urlGenerator={urlGenerator}
                    xRef={sortingRef}
                  />
                  <Pagination
                    urlGenerator={urlGenerator}
                    pagination={urlGenerator.query?.pagination}
                    count={count}
                    defaultPaginationSize={defaultPaginationSize}
                  />
                  {children(items, helpers)}
                  <Pagination
                    urlGenerator={urlGenerator}
                    pagination={urlGenerator.query?.pagination}
                    count={count}
                    defaultPaginationSize={defaultPaginationSize}
                    onPaginationClick={handleOnPaginationClick}
                  />
                </>
              )}
            </>
          )
          : null}
      </Loading>
    );
  };

export type {
  EntitiesListHelpers,
  SharedEntitiesListProps
};

export default EntitiesList;
