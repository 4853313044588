import UIProviderEnum from './enums/UIProviderEnum';

export const LOGIN_PROVIDER_APPLE = 'apple';
export const LOGIN_PROVIDER_FACEBOOK = 'facebook';
export const LOGIN_PROVIDER_GOOGLE = 'google';
export const LOGIN_PROVIDER_TWITTER = 'twitter';
export const LOGIN_PROVIDER_TWITTERV2 = 'twitterv2';

export const IMAGE_MAX_SIZE_IN_MB = 15;

export const ENTITY_IMAGE_THUMBNAIL_WIDTH_MAP_120 = 120;
export const ENTITY_IMAGE_THUMBNAIL_WIDTH_MOBILE_130 = 130;
export const ENTITY_IMAGE_THUMBNAIL_WIDTH_DESKTOP_150 = 150;

export const ENTITY_IMAGE_TILE_HEIGHT_150 = 150;

export const ENTITY_IMAGE_WIDTH_SMALL_300 = 300;
export const ENTITY_IMAGE_WIDTH_MEDIUM_600 = 600;
export const ENTITY_IMAGE_WIDTH_LARGE_1000 = 1000;

export const ENTITY_IMAGE_NO_IMAGE = '/app/item/no-image.svg';
export const ENTITY_IMAGE_NO_IMAGE_THUMBNAIL = '/app/item/no-image-thumbnail.svg';

export const ENTITY_IMAGE_WIDTH_THUMBNAIL_EDITOR = ENTITY_IMAGE_WIDTH_LARGE_1000;

export const UI_PROVIDER: UIProviderEnum = UIProviderEnum.ReactBootstrap;

export const PAGINATION_SORT_BY_CREATED_AT = '';
export const PAGINATION_SORT_BY_CREATED_AT_DESC = 'created-desc';
export const PAGINATION_SORT_BY_UPDATED_AT = 'updated';
export const PAGINATION_SORT_BY_UPDATED_AT_DESC = 'updated-desc';
export const PAGINATION_SORT_BY_TITLE = 'title';
export const PAGINATION_SORT_BY_TITLE_DESC = 'title-desc';
export const PAGINATION_SORT_BY_SITEMAP_ITEM_RENDER_ORDER = 'sitemap-item-render-order';

export const FORM_SIGNUP_USERNAME_FIELD = 'username';
export const FORM_SIGNUP_PASSWORD_FIELD = 'password';

export const ADVERT_ICON_COLOR_DEFAULT = '000000';
export const ADVERT_ICON_COLOR_LOST = 'f50000';
export const ADVERT_ICON_COLOR_FOUND = '45db2e';
export const ADVERT_ICON_COLOR_ADOPT = '8282ec';
