import React, { type ReactNode } from 'react';
import useService from '../../../../../hooks/useService';
import type { BaseEntityListItemApiModel } from '../../../../../models/api/base/BaseEntityListItemApiModel';
import type { BaseEntitiesQuery } from '../../../../../models/queries/base/BaseEntitiesQuery';
import type { IEntitiesListService } from '../../../../../services/interfaces/IEntitiesListService';
import type { EntitiesListSelector } from '../../../../../store/selectors/EntitiesListSelector';
import EntitiesList, { type EntitiesListHelpers, type SharedEntitiesListProps } from './EntitiesList';
import useEntitiesListFromState, { type EntitiesListFromStateReloadRef } from './hooks/useEntitiesListFromState';

interface EntitiesListFromStateHelpers<
  TService extends IEntitiesListService<TListItemModel>,
  TListItemModel extends BaseEntityListItemApiModel,
> extends EntitiesListHelpers<TService, TListItemModel> {
  forceReloadFromState: () => void;
}

interface EntitiesListFromStateProps<
  TService extends IEntitiesListService<TListItemModel>,
  TListItemModel extends BaseEntityListItemApiModel,
  TQuery extends BaseEntitiesQuery,
  TState,
> extends SharedEntitiesListProps<
  // TService,
  // TListItemModel,
  TQuery
> {
  serviceCtor: new (jwt: string) => TService;
  entityType: string;
  selector: EntitiesListSelector<TListItemModel, TQuery, TState>;
  namespace: string;
  modifyQuery?: (query: TQuery) => TQuery;
  reloadRef: React.Ref<EntitiesListFromStateReloadRef> | undefined;
  children: (items: TListItemModel[], helpers: EntitiesListFromStateHelpers<TService, TListItemModel>) => ReactNode;
}

// let renderCounter = 0;

const EntitiesListFromState: <
  TService extends IEntitiesListService<TListItemModel>,
  TListItemModel extends BaseEntityListItemApiModel,
  TQuery extends BaseEntitiesQuery,
  TState,
>(props: EntitiesListFromStateProps<TService, TListItemModel, TQuery, TState>) => React.ReactElement<
  EntitiesListFromStateProps<TService, TListItemModel, TQuery, TState>
> = <
  TIntinsicService extends IEntitiesListService<TIntrinsicListItemModel>,
  TIntrinsicListItemModel extends BaseEntityListItemApiModel,
  TIntrinsicQuery extends BaseEntitiesQuery,
  TIntrinsicState,
>({
  entityType,
  selector,
  serviceCtor,
  urlGenerator,
  content,
  sorts,
  sizes,
  defaultPaginationSize,
  namespace,
  onlySorts,
  reloadRef,
  paginationScrollToRef,
  modifyQuery,
  children,
}: EntitiesListFromStateProps<
  TIntinsicService,
  TIntrinsicListItemModel,
  TIntrinsicQuery,
  TIntrinsicState
>): React.ReactElement => {

    // console.debug(`Render EntitiesListFromState ${++renderCounter}`);

    const service = useService(serviceCtor);

    const { data, count, isLoading, reload, forceReload } = useEntitiesListFromState({
      urlGenerator,
      entityType,
      namespace,
      selector,
      modifyQuery,
      innerRef: reloadRef,
    });

    return (
      <EntitiesList
        items={data}
        count={count}
        isLoading={isLoading}
        service={service}
        urlGenerator={urlGenerator}
        content={content}
        sorts={sorts}
        sizes={sizes}
        defaultPaginationSize={defaultPaginationSize}
        paginationScrollToRef={paginationScrollToRef}
        onlySorts={onlySorts}
        reload={reload}
      >
        {(items, helpers) => children(items, {
          ...helpers,
          forceReloadFromState: forceReload
        })}
      </EntitiesList>
    );
  };

export type {
  EntitiesListFromStateHelpers
};

export default EntitiesListFromState;
