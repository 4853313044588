import React, { type ReactNode } from 'react';
import Spinner from './Spinner';

interface LoadingProps {
  render?: () => ReactNode;
  children?: () => ReactNode;
  isLoading?: boolean | undefined;
}

const Loading: React.FC<LoadingProps> = ({
  render,
  children,
  isLoading = true,
}: LoadingProps) => {
  return !isLoading
    ? (
      <>
        {render && render()}
        {children && children()}
      </>
    ) : (
      <div className='p-5 text-center'>
        <Spinner className='m-auto' />
      </div>
    );
};

export default Loading;
