import type { BaseEntityDetailApiModel } from '../../../models/api/base/BaseEntityDetailApiModel';
import type BaseApplicationError from '../../../models/errors/base/BaseApplicationError';
import type { BaseEntitiesQuery } from '../../../models/queries/base/BaseEntitiesQuery';
import type { EntityDetailState } from '../../actions/models/state/entities/EntityDetailState';
import type { EntitiesDetailSelector } from '../EntitiesDetailSelector';

const createEntityDetailSelector = <
  TDetailModel extends BaseEntityDetailApiModel,
  TState,
  TQuery extends BaseEntitiesQuery = BaseEntitiesQuery,
>
  (
    getEntitiesDetailState: (state: TState) => EntityDetailState<TDetailModel, TQuery> | undefined
  ): EntitiesDetailSelector<TDetailModel, TState> => {
  return {
    data: (state: TState): TDetailModel | undefined => getEntitiesDetailState(state)?.data,
    isLoading: (state: TState): boolean => !!getEntitiesDetailState(state)?.isFetching,
    error: (state: TState): BaseApplicationError | undefined => getEntitiesDetailState(state)?.error,
    id: (state: TState): string | undefined => getEntitiesDetailState(state)?.id,
    query: (state: TState): TQuery | undefined => getEntitiesDetailState(state)?.query,
  };
};

export default createEntityDetailSelector;
