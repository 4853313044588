import type { ISagaModule } from 'redux-dynamic-modules-saga';
import Utils from '../../../helpers/Utils';
import commonReducer from './commonReducer';
import type { IAppAwareState } from './models/IAppAwareState';
import notificationsReducer from './notificationsReducer';
import userReducer from './userReducer';
import userSaga from './userSaga';

const appModule: ISagaModule<IAppAwareState> = {
  id: 'app',
  reducerMap: {
    common: commonReducer,
    notifications: notificationsReducer,
    user: userReducer,
  },
  sagas: Utils.canUseDom()
    ? [
      userSaga,
    ]
    : [],
};

export default appModule;
