import EntityStateActionTypeEnum from '../../../../../constants/enums/EntityStateActionTypeEnum';
import type { FSAP } from '../../../base/fsa';
import type { BaseItemRequestPayload } from '../../../models/payloads/base/BaseItemRequestPayload';
import createEntityDetailActionName from './createEntityDetailActionName';

const createEntityDetailRequestAction = (entityType: string, id: string, namespace: string): FSAP<BaseItemRequestPayload> => ({
  type: createEntityDetailActionName(entityType, EntityStateActionTypeEnum.Request, namespace),
  payload: {
    id,
  },
});

export default createEntityDetailRequestAction;
