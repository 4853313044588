import loadable from '@loadable/component';
import React from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
// import ScrollToTop from '../../shared/elements/ScrollToTop';
import createBaseAppRouterConfig, { BaseAppRouterOptions } from '../../../../framework/base/common/components/layout/routers/BaseAppRouter';
import TaxonomyContentService from '../../../services/content/TaxonomyContentService';
import AdvertDetailModal from '../../modals/AdvertDetailModal';
import AdvertsListPage from '../../pages/adverts/AdvertsListPage';

const AboutPage = loadable(() => import(/* webpackChunkName: "page-content-about" */'../../pages/content/AboutPage'));
const AdminPage = loadable(() => import(/* webpackChunkName: "page-admin" */'../../../modules/admin/components/pages/AdminPage'));
const ConfirmEmailPage = loadable(() => import(/* webpackChunkName: "page-confirm-email" */'../../pages/common/ConfirmEmailPage'));
const HomePage = loadable(() => import(/* webpackChunkName: "page-home" */'../../pages/home/HomePage'));
const PrivacyPage = loadable(() => import(/* webpackChunkName: "page-content-privacy" */'../../pages/content/PrivacyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "page-profile" */'../../../modules/profile/components/pages/ProfilePage'));
const RodoPage = loadable(() => import(/* webpackChunkName: "page-content-rodo" */'../../pages/content/RodoPage'));
const TermsPage = loadable(() => import(/* webpackChunkName: "page-content-terms" */'../../pages/content/TermsPage'));

const appRoutesConfig: RouteObject[] = [
  { path: '', element: <HomePage /> },
  { path: '/ad/:id', element: <AdvertDetailModal /> },
];

TaxonomyContentService.getCategoryCodes().map((category) => (
  appRoutesConfig.push({
    path: `/${category}`,
    element: <AdvertsListPage categoryCode={category} />
  })
));

const appRoutesOptions: BaseAppRouterOptions = {
  appRoutes: appRoutesConfig,
  aboutPageElement: <AboutPage />,
  adminPage: () => <AdminPage />,
  confirmEmailPagePageElement: <ConfirmEmailPage />,
  privacyPageElement: <PrivacyPage />,
  profilePage: () => <ProfilePage />,
  rodoPageElement: <RodoPage />,
  sitemapPageElement: <RodoPage />,
  termsPageElement: <TermsPage />,
};

const routes = createBaseAppRouterConfig(appRoutesOptions);

// let renderCounter = 0;

const AppRouter: React.FC = () => {

  // console.debug(`Render AppRouter ${++renderCounter}`, routes);

  const router = useRoutes(routes);

  return router;
};

export default AppRouter;
