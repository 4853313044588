import React, { type PropsWithChildren } from 'react';
import { DynamicModuleLoader, type IModuleTuple } from 'redux-dynamic-modules-react';

interface DynamicModuleLoaderWrapperProps {
  modules: IModuleTuple;
}

const DynamicModuleLoaderWrapper: React.FC<PropsWithChildren<DynamicModuleLoaderWrapperProps>> = ({
  modules,
  children,
}: PropsWithChildren<DynamicModuleLoaderWrapperProps>) => {
  return (
    <>
      {/* TODO */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <DynamicModuleLoader
        modules={modules}
      >
        {children}
      </DynamicModuleLoader>
    </>
  );
};

export default DynamicModuleLoaderWrapper;
