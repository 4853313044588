import { useMemo } from 'react';
import useJwt from './useJwt';

const useService = <T>(ctor: new (jwt: string) => T): T => {
  const jwt = useJwt();

  const service = useMemo(() => new ctor(jwt || ''), [jwt, ctor]);

  return service;
};

export default useService;
