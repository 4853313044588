import EntityStateActionTypeEnum from '../../../../../constants/enums/EntityStateActionTypeEnum';
import type BaseApplicationError from '../../../../../models/errors/base/BaseApplicationError';
import type { FSAP } from '../../../base/fsa';
import type { BaseFailedPayload } from '../../../models/payloads/base/BaseFailedPayload';
import createEntityDetailActionName from './createEntityDetailActionName';

const createEntityDetailFailAction = (entityType: string, error: BaseApplicationError, namespace: string): FSAP<BaseFailedPayload> => ({
  type: createEntityDetailActionName(entityType, EntityStateActionTypeEnum.Failed, namespace),
  payload: {
    error,
  },
});

export default createEntityDetailFailAction;
