import { ENTITY_IMAGE_NO_IMAGE_THUMBNAIL, ENTITY_IMAGE_THUMBNAIL_WIDTH_DESKTOP_150, ENTITY_IMAGE_THUMBNAIL_WIDTH_MAP_120, ENTITY_IMAGE_THUMBNAIL_WIDTH_MOBILE_130, ENTITY_IMAGE_WIDTH_LARGE_1000, ENTITY_IMAGE_WIDTH_MEDIUM_600, ENTITY_IMAGE_WIDTH_SMALL_300 } from '../constants/BaseCommonConstants';
import EntityImageSizeEnum from '../constants/enums/EntityImageSizeEnum';
import EntityImageThumbnailSizeEnum from '../constants/enums/EntityImageThumbnailSizeEnum';
import BaseRendererRoutePaths from '../constants/path/BaseRendererRoutePaths';
import type { TemporaryImageDetailWebModel } from '../models/api/temporaryImage/TemporaryImageDetailWebModel';
import type { ImageModel } from '../models/entities/image/base/ImageModel';
import type { ImageWithSizeModel } from '../models/entities/image/base/ImageWithSizeModel';

class PathHelper {

  public static getEntityImageUrl(entityTypeUrlAlias: string, entityId: string, image: ImageModel, size: EntityImageSizeEnum, webp = true): string {
    const width = PathHelper.getImageWidth(size)?.toString();

    return PathHelper.urlJoinEntity(entityTypeUrlAlias, entityId, image.id, width, PathHelper.getImageName(image.name, webp));
  }

  public static getEntityImageThumbnailUrl(entityTypeUrlAlias: string, entityId: string, size: EntityImageThumbnailSizeEnum, image?: ImageModel): string {
    const width = PathHelper.getImageThumbnailWidth(size);

    return image
      ? PathHelper.urlJoinEntity(entityTypeUrlAlias, entityId, image.id, 'thumbnail', width, PathHelper.getImageName(image.name, true))
      : `/images${ENTITY_IMAGE_NO_IMAGE_THUMBNAIL}`;
  }

  public static getTmpImageUrl(image: ImageModel, size: EntityImageSizeEnum, webp = true): string {
    const width = PathHelper.getImageWidth(size)?.toString();
    const name = PathHelper.getImageName(image.name, webp);

    return PathHelper.urlJoinTmp(image.id, width, name);
  }

  public static getTmpImageThumbnailUrl(image: TemporaryImageDetailWebModel, size: EntityImageThumbnailSizeEnum, webp = true): string {
    const width = PathHelper.getImageThumbnailWidth(size);
    const name = PathHelper.getImageName(image.name, webp);

    return PathHelper.urlJoinTmp(image.id, 'thumbnail', width, name) + (image.version && image.version > 0 ? `?v=${image.version}` : '');
  }

  public static getImageWidth(size: EntityImageSizeEnum): number | undefined {
    switch (size) {
      case EntityImageSizeEnum.Source:
        return undefined;
      case EntityImageSizeEnum.Small300:
        return ENTITY_IMAGE_WIDTH_SMALL_300;
      case EntityImageSizeEnum.Medium600:
        return ENTITY_IMAGE_WIDTH_MEDIUM_600;
      case EntityImageSizeEnum.Large1000:
        return ENTITY_IMAGE_WIDTH_LARGE_1000;
      default:
        throw new Error(`Invalid image size ${size}.`);
    }
  }

  public static getImageThumbnailWidth(size: EntityImageThumbnailSizeEnum): string | undefined {
    switch (size) {
      case EntityImageThumbnailSizeEnum.Source:
        return undefined;
      case EntityImageThumbnailSizeEnum.Map120:
        return ENTITY_IMAGE_THUMBNAIL_WIDTH_MAP_120.toString();
      case EntityImageThumbnailSizeEnum.Mobile130:
        return ENTITY_IMAGE_THUMBNAIL_WIDTH_MOBILE_130.toString();
      case EntityImageThumbnailSizeEnum.Desktop150:
        return ENTITY_IMAGE_THUMBNAIL_WIDTH_DESKTOP_150.toString();
      default:
        throw new Error(`Invalid image thumbnail size ${size}.`);
    }
  }

  public static getImageResizedHeight(image: ImageWithSizeModel, size: EntityImageSizeEnum): number | undefined {
    const width = PathHelper.getImageWidth(size);

    if (!width || !image.width) {
      return undefined;
    }

    const radio = width / image.width;

    return image.height * radio;
  }

  private static urlJoinEntity = (entityTypeUrlAlias: string, ...parts: (string | undefined)[]): string => {
    return `${BaseRendererRoutePaths.IMAGES}${entityTypeUrlAlias}/${PathHelper.urlJoin(...parts)}`;
  };

  private static urlJoinTmp = (...parts: (string | undefined)[]): string => {
    return `${BaseRendererRoutePaths.IMAGES}${BaseRendererRoutePaths.IMAGES_TMP}/${PathHelper.urlJoin(...parts)}`;
  };

  private static urlJoin(...parts: (string | undefined)[]): string {
    return parts.filter(p => !!p).join('/');
  }

  private static getImageName = (name: string, webp?: boolean): string => {
    if (webp) {
      name = name.replace(/\.[\w]{3,4}$/, '.webp');
    }

    return encodeURIComponent(name);
  };
}

export default PathHelper;
