import classNames from 'classnames';
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './MainNavigation.scss';

const MainNavigation: React.FC = () => {

  const [collapse, setCollapse] = useState(true);

  const handleOnClickToggleNavigation = () => {
    setCollapse((collapse) => !collapse);
  };

  return (
    <nav className='navbar navbar-expand-sm navbar-dark bg-dark app-main-nav'>
      <div className='container-fluid'>
        <Link to='/' className='navbar-brand'>Anna Michałowska - Rzeźbajka</Link>
        <button
          className={classNames('navbar-toggler', { 'collapsed': collapse })}
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#main-navigation'
          aria-controls='main-navigation'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={handleOnClickToggleNavigation}
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div
          className={classNames('navbar-collapse collapse', { 'show': !collapse })}
          id='main-navigation'
        >
          <ul className='navbar-nav me-auto mb-2 mb-sm-0'>
            <li className='nav-item'>
              <NavLink
                to='/painting'
                className='nav-link'
              >
                Malarstwo
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                to='/sculpture'
                className='nav-link'
              >
                Rzeźba
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                to='/drawing'
                className='nav-link'
              >
                Rysunek
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                to='/about'
                className='nav-link'
              >
                O Mnie
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MainNavigation;
