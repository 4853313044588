class BaseWebUrlPaths {
  static ABOUT = '/about';
  static ADMIN_PART_SOCIAL_APPLICATIONS = 'applications';
  static ADMIN_PART_USERS = 'users';
  static CONTACT = '/contact';
  static FORGOTTEN_PASSWORD = '/forgotten-password';
  static PRIVACY = '/privacy';
  static PROFILE_PART_APPLICATIONS = 'applications';
  static PROFILE_PART_EDIT_DATA = 'edit-data';
  static PROFILE_PART_LOGINS = 'logins';
  static PROFILE_PART_PASSWORD = 'password';
  static PROFILE_PART_REMOVE_ACCOUNT = 'remove-account';
  static RODO = '/rodo';
  static SIGNIN = '/signin';
  static SIGNUP = '/signup';
  static SITEMAP = '/sitemap';
  static TERMS = '/terms';
}

export default BaseWebUrlPaths;
