import { loadableReady, type LoadableReadyOptions } from '@loadable/component';
import _throttle from 'lodash/throttle';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import type { Store } from 'redux';
import { createStore, type IModule } from 'redux-dynamic-modules';
import { getSagaExtension } from 'redux-dynamic-modules-saga';
import { getThunkExtension } from 'redux-dynamic-modules-thunk';
import type { BaseState } from '../common/store/BaseState';
import appModule from '../common/store/modules/app/appModule';
import { userRetrieve } from '../common/store/modules/app/userReducer';
import { BUILD_LOADABLE_CHUNK_LOADING_GLOBAL } from './buildConstantsClient';

const LOCAL_STORAGE_STATE = 'state';

interface BaseLocalStorageState {
  jwt?: string | undefined;
}

interface AppProps {
  appComponent: React.FC;
  store: Store;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface StoreModuleByPreloadedState<T = any> {
  selector: (state: T) => unknown | undefined;
  factory: () => Promise<IModule<T>>;
}

function config<T extends object>(factory: () => Promise<IModule<T>>, condition: (state: T) => unknown | undefined): StoreModuleByPreloadedState<T> {
  return {
    factory: factory,
    selector: condition,
  };
}

// let renderCounter = 0;

const App: React.FC<AppProps> = ({ appComponent, store }: AppProps) => {

  // console.debug(`Render App ${++renderCounter}`);

  const AppComponent = appComponent;

  return (
    <Provider
      store={store}
    >
      <BrowserRouter>
        <AppComponent />
      </BrowserRouter>
    </Provider>
  );
};

abstract class BaseAppBootstrap {

  public async run(appComponent: React.FC): Promise<void> {
    const options = {
      chunkLoadingGlobal: BUILD_LOADABLE_CHUNK_LOADING_GLOBAL
    };

    const store = await this.initializeStore();

    loadableReady(() => {
      const root = document.getElementById('app');

      if (root) {
        hydrateRoot(root, (
          // <React.StrictMode>
          <App
            appComponent={appComponent}
            store={store}
          />
          // </React.StrictMode>
        ));
      } else {
        console.error('Missing root');
      }
    }, options as LoadableReadyOptions);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected createInitialModules(): IModule<any>[] {
    const initialModules = [
      appModule,
    ];

    return initialModules;
  }

  protected createModulesByPreloadedStateConfig(): StoreModuleByPreloadedState[] {
    const result: StoreModuleByPreloadedState[] = [
      config(async () => {
        const entityFormModule = (await import('../common/store/modules/entityForm/entityFormModule')).default;
        return entityFormModule;
      }, (state) => state.entityForm),
      config(async () => {
        const authModule = (await import('../common/store/modules/auth/authModule')).default;
        return authModule;
      }, (state) => state.auth),
      config(async () => {
        const signupModule = (await import('../common/store/modules/signup/signupModule')).default;
        return signupModule;
      }, (state) => state.signup),
    ];

    return result;
  }

  protected createStateToSaveInLocalStorage(state: BaseState): BaseLocalStorageState {
    const localStorageState: BaseLocalStorageState = {
      jwt: state.user.jwt,
    };

    return localStorageState;
  }

  protected restoreStateFromLocalStorage(store: Store<BaseState>, localStorageState: BaseLocalStorageState): void {
    if (localStorageState.jwt && !store.getState().user?.jwt) {
      store.dispatch(userRetrieve({ jwt: localStorageState.jwt }));
    }
  }

  protected readStateFromLocalStorage = (store: Store<BaseState>): void => {
    const value = localStorage.getItem(LOCAL_STORAGE_STATE);

    try {
      const localStorageState: BaseLocalStorageState = JSON.parse(value || '') as BaseLocalStorageState;

      this.restoreStateFromLocalStorage(store, localStorageState);
    } catch (err) {
      // empty
    }
  };

  private async initializeStore(): Promise<Store<BaseState>> {
    const preloadedState = this.createPreloadedStateFromBackEnd();

    const initialModules = this.createInitialModules();

    for (const config of this.createModulesByPreloadedStateConfig()) {
      if (config.selector(preloadedState) !== undefined) {
        initialModules.push(await config.factory());
      }
    }

    const store = createStore<BaseState>(
      {
        initialState: preloadedState,
        extensions: [
          getThunkExtension(),
          getSagaExtension()
        ],
      },
      ...initialModules,
    );

    this.readStateFromLocalStorage(store);

    store.subscribe(_throttle(() => { this.saveStateInLocalStorage(store); }, 1000));

    return store;
  }

  private saveStateInLocalStorage(store: Store<BaseState>) {
    localStorage.setItem(LOCAL_STORAGE_STATE, JSON.stringify(this.createStateToSaveInLocalStorage(store.getState())));
  }

  private createPreloadedStateFromBackEnd(): BaseState {
    const preloadedState = (window as never)['8qj1f'];

    const result = preloadedState as BaseState;

    return result;
  }
}

export {
  config,
  type BaseLocalStorageState,
  type StoreModuleByPreloadedState
};

export default BaseAppBootstrap;
