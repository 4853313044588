import React, { type ChangeEvent, type ForwardedRef } from 'react';
import BaseAppCommonConstants from '../../../constants/BaseAppCommonConstants';
import type { IUrlGenerator } from '../../../helpers/IUrlGenerator';
import type { IEntitiesUrlQueryMapper } from '../../../helpers/mappers/interfaces/IEntitiesUrlQueryMapper';
import type { BaseEntitiesQuery } from '../../../models/queries/base/BaseEntitiesQuery';
import type { BaseEntitiesQueryFilters } from '../../../models/queries/base/BaseEntitiesQueryFilters';

interface SortingProps<
  TQuery extends BaseEntitiesQuery<TQueryFilters>,
  TQueryFilters extends BaseEntitiesQueryFilters = BaseEntitiesQueryFilters,
> {
  sorts?: { [key: string]: string; } | undefined;
  sizes?: number[] | undefined;
  defaultPaginationSize?: number | undefined;
  urlGenerator: IUrlGenerator<TQuery, IEntitiesUrlQueryMapper<TQuery>, TQueryFilters>;
  xRef: ForwardedRef<HTMLElement>;
}

const Sorting: <
  TQuery extends BaseEntitiesQuery<TQueryFilters>,
  TQueryFilters extends BaseEntitiesQueryFilters = BaseEntitiesQueryFilters,
>(
  props: SortingProps<TQuery, TQueryFilters>
) => React.ReactElement<
  SortingProps<TQuery, TQueryFilters>
> | null = <
  TIntrinsicQuery extends BaseEntitiesQuery<TIntrinsicQueryFilters>,
  TIntrinsicQueryFilters extends BaseEntitiesQueryFilters = BaseEntitiesQueryFilters
>({
  sorts,
  sizes,
  defaultPaginationSize,
  urlGenerator,
  xRef,
}: SortingProps<TIntrinsicQuery, TIntrinsicQueryFilters>) => {

    const def = defaultPaginationSize ?? BaseAppCommonConstants.getInstance().PAGINATION_DEFAULT_SIZE;

    const defaultSizes = [def, def * 2, def * 4];

    const handleOnChangeSort = (event: ChangeEvent<HTMLSelectElement>) => {
      urlGenerator.redirect((draft) => ({
        ...draft,
        pagination: {
          ...draft.pagination,
          page: undefined,
          sort: event.target.value,
        }
      }));
    };

    const handleOnChangePageSize = (event: ChangeEvent<HTMLSelectElement>) => {
      const size = parseInt(event.target.value);

      urlGenerator.redirect((draft) => ({
        ...draft,
        pagination: {
          ...draft.pagination,
          page: undefined,
          size: size === def ? undefined : size,
        }
      }));
    };

    const selectedSize = urlGenerator.query.pagination?.size ?? def;

    return (
      <aside
        className='row row-cols-auto mt-2 justify-content-end'
        ref={xRef}
      >
        {sorts && Object.keys(sorts).length > 1 && (
          <div className='col mb-2'>
            <div className='row row-cols-auto'>
              <label className='col col-form-label col-form-label-sm' htmlFor='pagination-sort'>Sortowanie</label>
              <div className='col'>
                <select
                  className='form-select form-select-sm'
                  value={urlGenerator.query.pagination?.sort ?? ''}
                  onBlur={void 0}
                  onChange={handleOnChangeSort}
                >
                  {Object.keys(sorts).map((key, index) => (
                    <option
                      key={index}
                      value={key}
                    >
                      {sorts[key]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
        {(sizes ?? defaultSizes).length > 0 && (
          <div className='col mb-2'>
            <div className='row row-cols-auto'>
              <label className='col col-form-label col-form-label-sm' htmlFor='pagination-size'>Ilość na stronę</label>
              <div className='col'>
                <select
                  className='form-select form-select-sm'
                  value={selectedSize}
                  onBlur={void 0}
                  onChange={handleOnChangePageSize}
                >
                  {(sizes ?? defaultSizes).map((size, index) => (
                    <option
                      key={index}
                      value={size}
                    >
                      {size}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
      </aside>
    );
  };

export default Sorting;
