import classNames from 'classnames';
import React, { type CSSProperties } from 'react';

interface SpinnerProps {
  className?: string;
  style?: CSSProperties;
}

const Spinner: React.FC<SpinnerProps> = ({ className, style }: SpinnerProps) => {
  return (
    <span
      className={classNames('spinner-border', className)}
      style={style}
      role='status'
    />
  );
};

export default Spinner;
