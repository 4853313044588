import EntityStateActionTypeEnum from '../../../../../constants/enums/EntityStateActionTypeEnum';
import type { BaseEntitiesQuery } from '../../../../../models/queries/base/BaseEntitiesQuery';
import type { FSAP } from '../../../base/fsa';
import type { BaseItemQueryPayload } from '../../../models/payloads/base/BaseItemQueryPayload';
import createEntityDetailActionName from './createEntityDetailActionName';

const createEntityDetailQueryAction = <TQuery extends BaseEntitiesQuery>(entityType: string, query: TQuery, namespace: string): FSAP<BaseItemQueryPayload<TQuery>> => ({
  type: createEntityDetailActionName(entityType, EntityStateActionTypeEnum.Query, namespace),
  payload: {
    query,
  },
});

export default createEntityDetailQueryAction;
