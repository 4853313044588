
interface LabelsInterface {
  blank: string;
  category: Record<string, string>;
  published: Record<string, string>;
  gender: {
    male: string,
    female: string,
  };
  genderDog: {
    male: string,
    female: string,
  };
  size: {
    small: string,
    medium: string,
    large: string,
  };
}

export const Labels: LabelsInterface = {
  blank: 'Nieustawione',
  category: {
    painting: 'Obraz',
    sculpture: 'Rzeźba',
    drawing: 'Rysunek',
    scenography: 'Scenografia',
  },
  published: {
    'week': 'W tym tygodniu',
    'two-weeks': '2 tygodnie temu',
    'month': 'Miesiąc temu',
  },
  gender: {
    male: 'Samiec',
    female: 'Samica',
  },
  genderDog: {
    male: 'Pies',
    female: 'Suka',
  },
  size: {
    small: 'Mały',
    medium: 'Średni',
    large: 'Duży',
  },
};

export class ContentHelper {

}
