import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, type Path } from 'react-router-dom';
import type { DeepReadonly } from '../constants/types/UtilityTypes';
import DebugHelper from '../helpers/DebugHelper';
import type { IUrlGenerator } from '../helpers/IUrlGenerator';
import type { IEntitiesUrlQueryMapper } from '../helpers/mappers/interfaces/IEntitiesUrlQueryMapper';
import type { BaseEntitiesQuery } from '../models/queries/base/BaseEntitiesQuery';
import type { BaseEntitiesQueryFilters } from '../models/queries/base/BaseEntitiesQueryFilters';

const useUrlGenerator = <
  TQuery extends BaseEntitiesQuery<TQueryFilters>,
  TMapper extends IEntitiesUrlQueryMapper<TQuery>,
  TQueryFilters extends BaseEntitiesQueryFilters = BaseEntitiesQueryFilters
>(
  ctor: (new () => TMapper) | TMapper,
): IUrlGenerator<TQuery, TMapper, TQueryFilters> => {

  const location = useLocation();

  const navigate = useNavigate();

  const mapper = useMemo(() => {
    if (typeof ctor === 'function') {
      return new ctor();
    } else if (typeof ctor === 'object') {
      return ctor;
    } else {
      throw new Error('Invalid argument');
    }
  }, [ctor]);

  const query = useMemo(() => {
    return mapper.parse(location.search);
  }, [location.search, mapper]);

  const generateUrl = useCallback((recipe: (draft: DeepReadonly<TQuery>) => TQuery): Path => {
    const baseProduct = recipe(query);
    const product = {
      ...baseProduct,
      // pagination: {
      //   ...baseProduct.pagination,
      //   page: undefined,
      // },
    };

    return {
      pathname: location.pathname,
      search: mapper.stringifyForURL(product),
      hash: '',
    };
  }, [location.pathname, mapper, query]);

  const generateUrlToFilters = useCallback((recipe: (draft: DeepReadonly<TQueryFilters> | undefined) => TQueryFilters | undefined): Path => {
    const filters = recipe(query.filters);

    return generateUrl((draft) => ({
      ...draft,
      filters,
      pagination: {
        ...draft.pagination,
        page: undefined,
      },
    }));
  }, [generateUrl, query.filters]);

  const redirect = useCallback((recipe: (draft: TQuery) => TQuery, replace = false): void => {
    const to = generateUrl(recipe);

    if (DebugHelper.isDebug()) {
      console.log('useUrlGenerator redirect TO', to);
    }

    navigate(to, { replace: replace });
  }, [generateUrl, navigate]);

  const redirectToFilters = useCallback((recipe: (draft: DeepReadonly<TQueryFilters> | undefined) => TQueryFilters | undefined, replace = false): void => {
    const to = generateUrlToFilters(recipe);

    if (DebugHelper.isDebug()) {
      console.log('useUrlGenerator redirect TO', to);
    }

    navigate(to, { replace: replace });
  }, [generateUrlToFilters, navigate]);

  return {
    mapper,
    query,
    generateUrl,
    redirect,
    redirectToFilters,
  };
};

export default useUrlGenerator;
