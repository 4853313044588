import type { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';
import AuthRetrieveService from '../../../../client/services/AuthRetrieveService';
import type { SignInResult } from '../../../models/auth/SignInResult';
import { userRetrieve, userRetrieveFailed, userRetrieveSucceeded, type RetrievePayload } from './userReducer';

function* retrive(action: PayloadAction<RetrievePayload>): Generator {
  try {
    const service = new AuthRetrieveService(action.payload.jwt);
    // const result = (yield call(service.retrieve)) as SignInResult; // @TODO StrongType
    const result = (yield service.retrieve()) as SignInResult; // @TODO StrongType
    yield put(userRetrieveSucceeded(result));
  } catch (err) {
    yield put(userRetrieveFailed(undefined));
  }
}

function* userSaga(): Generator {
  yield takeLatest<PayloadAction<RetrievePayload>>(userRetrieve.type, retrive);
}

export default userSaga;
