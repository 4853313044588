import type { BaseEntityListItemApiModel } from '../../../models/api/base/BaseEntityListItemApiModel';
import type BaseApplicationError from '../../../models/errors/base/BaseApplicationError';
import type { BaseEntitiesQuery } from '../../../models/queries/base/BaseEntitiesQuery';
import type { EntitiesListState } from '../../actions/models/state/entities/EntitiesListState';
import type { EntitiesListSelector } from '../EntitiesListSelector';
import createEntitiesListItemsListSelector from './createEntitiesListItemsListSelector';

const createEntitiesListSelector = <
  TListItemlModel extends BaseEntityListItemApiModel,
  TQuery extends BaseEntitiesQuery,
  TState,
>(
  getEntitiesItemsState: (state: TState) => EntitiesListState<TListItemlModel, TQuery> | undefined
): EntitiesListSelector<TListItemlModel, TQuery, TState> => {
  return {
    ...createEntitiesListItemsListSelector<TListItemlModel, TState>(state => getEntitiesItemsState(state)?.data),
    loading: (state: TState): boolean => !!getEntitiesItemsState(state)?.isFetching,
    error: (state: TState): BaseApplicationError | undefined => getEntitiesItemsState(state)?.error,
    query: (state: TState): TQuery | undefined => getEntitiesItemsState(state)?.query,
  };
};

export default createEntitiesListSelector;
