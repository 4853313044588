import classNames from 'classnames';
import React, { useCallback } from 'react';
import { Link, type To } from 'react-router-dom';
import BaseAppCommonConstants from '../../../constants/BaseAppCommonConstants';
import type { IUrlGenerator } from '../../../helpers/IUrlGenerator';
import type { IEntitiesUrlQueryMapper } from '../../../helpers/mappers/interfaces/IEntitiesUrlQueryMapper';
import type { BaseEntitiesQuery } from '../../../models/queries/base/BaseEntitiesQuery';
import type { BaseEntitiesQueryFilters } from '../../../models/queries/base/BaseEntitiesQueryFilters';
import type { PaginationModel } from '../../../models/system/pagination/PaginationModel';

const MAX_ITEMS_LEFT_FROM_CURRENT_PAGE = 3;
const MAX_ITEMS_RIGHT_FROM_CURRENT_PAGE = 2;
const MAX_ITEMS = 7;

const usePaginationItems = (
  currentPageIndex: number,
  lastPageIndex: number,
): PaginationItem[] => {
  const items: PaginationItem[] = [];

  const maxItems = Math.min(MAX_ITEMS, lastPageIndex);

  if (maxItems >= 1) {
    items.push({
      index: 0,
      isGap: false,
      desc: 'First item'
    });

    let itemsToAddLeftFromCurrentPage = Math.max(0, Math.min(currentPageIndex - 1, MAX_ITEMS_LEFT_FROM_CURRENT_PAGE));
    let itemsToAddRightFromCurrentPage = Math.max(0, Math.min(lastPageIndex - currentPageIndex - 1, MAX_ITEMS_RIGHT_FROM_CURRENT_PAGE));

    if (itemsToAddLeftFromCurrentPage + itemsToAddRightFromCurrentPage < maxItems - 2) {
      const more = maxItems - 2 - itemsToAddLeftFromCurrentPage - itemsToAddRightFromCurrentPage;

      if (currentPageIndex < lastPageIndex - currentPageIndex) {
        itemsToAddRightFromCurrentPage += more;
      } else {
        itemsToAddLeftFromCurrentPage += more;
      }
    }

    for (let index = itemsToAddLeftFromCurrentPage; index > 0; index--) {
      const pageIndex = currentPageIndex - index;

      items.push({
        index: pageIndex,
        isGap: index === itemsToAddLeftFromCurrentPage && pageIndex > 1,
        desc: 'Add Left'
      });
    }

    if (currentPageIndex > 0 && currentPageIndex < lastPageIndex) {
      items.push({
        index: currentPageIndex,
        isGap: false,
        desc: 'Current page item',
      });
    }

    for (let index = 0; index < itemsToAddRightFromCurrentPage; index++) {
      const pageIndex = currentPageIndex + index + 1;

      items.push({
        index: pageIndex,
        isGap: index === itemsToAddRightFromCurrentPage - 1 && lastPageIndex - pageIndex > 1,
        desc: 'Add right'
      });
    }

    items.push({
      index: lastPageIndex,
      isGap: false,
      desc: 'Last item',
    });
  }

  return items;
};

interface PaginationProps<
  TQuery extends BaseEntitiesQuery<TQueryFilters>,
  TQueryFilters extends BaseEntitiesQueryFilters = BaseEntitiesQueryFilters,
> {
  urlGenerator: IUrlGenerator<TQuery, IEntitiesUrlQueryMapper<TQuery>, TQueryFilters>;
  pagination: PaginationModel | undefined;
  count: number;
  defaultPaginationSize?: number | undefined;
  onPaginationClick?: () => void;
}

interface PaginationItem {
  index: number;
  isGap: boolean;
  desc?: string;
}

const Pagination: <
  TQuery extends BaseEntitiesQuery<TQueryFilters>,
  TQueryFilters extends BaseEntitiesQueryFilters = BaseEntitiesQueryFilters,
>(
  props: PaginationProps<TQuery, TQueryFilters>
) => React.ReactElement<
  PaginationProps<TQuery, TQueryFilters>
> | null = <
  TIntrinsicQuery extends BaseEntitiesQuery<TIntrinsicQueryFilters>,
  TIntrinsicQueryFilters extends BaseEntitiesQueryFilters = BaseEntitiesQueryFilters
>({
  urlGenerator,
  pagination,
  count,
  defaultPaginationSize,
  onPaginationClick,
}: PaginationProps<TIntrinsicQuery, TIntrinsicQueryFilters>) => {

    const currentPageIndex = pagination ? pagination.page ?? 0 : 0;
    const itemsPerPage = pagination
      ? pagination.size ?? defaultPaginationSize ?? BaseAppCommonConstants.getInstance().PAGINATION_DEFAULT_SIZE
      : defaultPaginationSize ?? BaseAppCommonConstants.getInstance().PAGINATION_DEFAULT_SIZE;
    const lastPageIndex = Math.ceil(count / itemsPerPage) - 1;

    const items = usePaginationItems(currentPageIndex, lastPageIndex);

    const getUrl = useCallback((i: number): Partial<To> => {
      const result = urlGenerator.generateUrl((draft) => ({
        ...draft,
        pagination: {
          ...draft.pagination,
          page: i === 0 ? undefined : i,
        }
      }));

      return result;
    }, [urlGenerator]);

    const handleOnClick = () => {
      if (onPaginationClick) {
        onPaginationClick();
      }
    };

    return items.length > 1
      ? (
        <nav className='py-2'>
          <ul className='pagination justify-content-center'>
            <li
              className={classNames('page-item', { disabled: currentPageIndex === 0 })}
            >
              <Link
                to={getUrl(Math.max(0, currentPageIndex - 1))}
                className='page-link'
                rel='prev'
                aria-label="Poprzednia"
                onClick={handleOnClick}
              >
                <span aria-hidden="true">&laquo;</span>
              </Link>
            </li>
            {items.map((item, index) => (
              <li
                key={index}
                className={classNames('page-item', { active: currentPageIndex === item.index })}
              >
                <Link
                  to={getUrl(item.index)}
                  className='page-link'
                  onClick={handleOnClick}
                >
                  {item.isGap ? '...' : item.index + 1}
                </Link>
              </li>
            ))}
            <li
              className={classNames('page-item', { disabled: currentPageIndex === lastPageIndex })}
            >
              <Link
                to={getUrl(Math.min(currentPageIndex + 1, lastPageIndex))}
                className='page-link'
                rel='next'
                aria-label="Następna"
                onClick={handleOnClick}
              >
                <span aria-hidden='true'>&raquo;</span>
              </Link>
            </li>
          </ul>
        </nav>
      )
      : null;
  };

export default Pagination;
