import React from 'react';
import AppRouter from './routers/AppRouter';

const Main: React.FC = () => {
  return (
    <AppRouter />
  );
};

export default Main;
