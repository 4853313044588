import { produce } from 'immer';
import type { Action } from 'redux';
import EntityStateActionTypeEnum from '../../../../constants/enums/EntityStateActionTypeEnum';
import type { BaseEntityListItemApiModel } from '../../../../models/api/base/BaseEntityListItemApiModel';
import type { BaseEntitiesQuery } from '../../../../models/queries/base/BaseEntitiesQuery';
import type { FSAP } from '../../../actions/base/fsa';
import createEntitiesListActionName from '../../../actions/factories/entity/items/createEntitiesListActionName';
import type { BaseFailedPayload } from '../../../actions/models/payloads/base/BaseFailedPayload';
import type { BaseItemsRequestPayload } from '../../../actions/models/payloads/base/BaseItemsRequestPayload';
import type { BaseItemsSucceededPayload } from '../../../actions/models/payloads/base/BaseItemsSucceededPayload';
import type { EntitiesListState } from '../../../actions/models/state/entities/EntitiesListState';

const entitiesListReducerFactory = <
  TModel extends BaseEntityListItemApiModel,
  TQuery extends BaseEntitiesQuery
>(
  entityType: string, // EntityTypeEnum,
  namespace: string
): ((base: EntitiesListState<TModel, TQuery> | undefined, action: Action) => EntitiesListState<TModel, TQuery>) => {

  return (base: EntitiesListState<TModel, TQuery> | undefined, action: Action): EntitiesListState<TModel, TQuery> => {
    const nextState = produce<EntitiesListState<TModel, TQuery>, EntitiesListState<TModel, TQuery>>(base ? base : {}, draft => {
      if (!draft) {
        draft = {};
      }

      switch (action.type) {
        case createEntitiesListActionName(entityType, EntityStateActionTypeEnum.Request, namespace):
          draft.isFetching = true;
          draft.query = (action as FSAP<BaseItemsRequestPayload<TQuery>>).payload.query;
          break;
        case createEntitiesListActionName(entityType, EntityStateActionTypeEnum.Succeeded, namespace):
          draft.isFetching = undefined;
          draft.data = (action as FSAP<BaseItemsSucceededPayload<TModel>>).payload.data;
          draft.error = undefined;
          break;
        case createEntitiesListActionName(entityType, EntityStateActionTypeEnum.Failed, namespace):
          draft.isFetching = undefined;
          draft.data = undefined;
          draft.error = (action as FSAP<BaseFailedPayload>).payload.error;
          break;
        case createEntitiesListActionName(entityType, EntityStateActionTypeEnum.Clear, namespace):
          draft.isFetching = undefined;
          draft.data = undefined;
          draft.error = undefined;
          draft.query = undefined;
      }
    });

    return nextState;
  };
};

export default entitiesListReducerFactory;
