import BaseAppBootstrap, { config, StoreModuleByPreloadedState } from '../framework/base/client/BaseAppBootstrap';

class AppBootstrap extends BaseAppBootstrap {

  protected override createModulesByPreloadedStateConfig(): StoreModuleByPreloadedState[] {
    const result: StoreModuleByPreloadedState[] = [
      ...super.createModulesByPreloadedStateConfig(),
      config(async () => {
        const homeModule = (await import('../common/store/modules/home/homeModule')).default;
        return homeModule;
      }, (state) => state.home),
      config(async () => {
        const homeModule = (await import('../common/store/modules/adverts/advertsModule')).default;
        return homeModule;
      }, (state) => state.adverts),
      config(async () => {
        const profileModule = (await import('../common/modules/profile/store/modules/profile/profileModule')).default;
        return profileModule;
      }, (state) => state.profile),
    ];

    return result;
  }
}

export default AppBootstrap;
