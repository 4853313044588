import { createSlice, type CaseReducer, type PayloadAction } from '@reduxjs/toolkit';
import type { CommonState } from './models/CommonState';

const initialState: CommonState = {
  showPageNotFound404: undefined,
  googleAnalyticsMeasurementId: undefined,
  oAuthGoogleClientId: undefined,
  oAuthIsEnabledGoogle: undefined,
  oAuthIsEnabledFacebook: undefined,
  oAuthIsEnabledTwitter: undefined,
  oAuthIsEnabledTwitterV2: undefined,
};

const setShowPageNotFound404Reducer: CaseReducer<CommonState, PayloadAction<boolean>> = (state, action) => {
  state.showPageNotFound404 = action.payload;
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setShowPageNotFound404: setShowPageNotFound404Reducer,
  },
});

const {
  setShowPageNotFound404
} = commonSlice.actions;

export {
  setShowPageNotFound404
};

const commonReducer = commonSlice.reducer;

export default commonReducer;
