import _debounce from 'lodash/debounce';
import { forwardRef, RefObject, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';

type MasonryHandle = {
  ensure: () => void,
  destroy: () => void;
}

interface MasProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lib: any;
  gridHtmlDivElementRef: RefObject<HTMLDivElement>;
}

const Mas: React.ForwardRefRenderFunction<MasonryHandle, MasProps> = ({ lib, gridHtmlDivElementRef }: MasProps, forwardedRef) => {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [masonry, setMasonry] = useState<any>();

  const layout = useCallback(() => {
    if (masonry) {
      masonry.layout();
    }
  }, [masonry]);

  const layoutDebouncedMemo = useMemo(() => _debounce(() => {
    layout();
  }, 300), [layout]);

  const create = useCallback(() => {
    if (gridHtmlDivElementRef.current) {
      setMasonry(new lib.default(gridHtmlDivElementRef.current, {
        itemSelector: '.grid-item',
        percentPosition: true,
        transitionDuration: 0.2,
      }));
    }
  }, [gridHtmlDivElementRef, lib.default]);

  useImperativeHandle(forwardedRef, () => ({
    ensure() {
      if (!masonry) {
        create();
      } else {
        layoutDebouncedMemo();
      }
    },
    destroy() {
      if (masonry) {
        masonry.destroy();
        setMasonry(undefined);
      }
    }
  }));

  useEffect(() => {
    if (!masonry) {
      create();
    }
  }, [create, masonry]);

  return null;
}

const FancyInput = forwardRef(Mas);

export {
  MasonryHandle,
};

export default FancyInput;
