import React, { PropsWithChildren } from 'react';
import Loading from '../../../../framework/base/common/components/shared/elements/Loading';

interface LoadingFadeContentProps {
  isLoading?: boolean;
}

const LoadingFadeContent: React.FC<PropsWithChildren<LoadingFadeContentProps>> = ({
  children,
  isLoading = true,
}: PropsWithChildren<LoadingFadeContentProps>) => {
  return (
    <>
      {isLoading && (
        <div
          className='w-100 h-100 position-absolute'
          style={{
            background: 'rgba(255, 255, 255, 0.5)',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <div
            className='d-flex justify-content-center'
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translateY(-50%) translateX(-50%)'
            }}
          >
            <Loading isLoading={true} />
          </div>
        </div>
      )}
      {children}
    </>
  );
};

export default LoadingFadeContent;
