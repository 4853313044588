import loadable from '@loadable/component';
import React, { useRef } from 'react';
// import Lightbox from 'react-image-lightbox';
import EntityImageSizeEnum from '../../../framework/base/common/constants/enums/EntityImageSizeEnum';
import PathHelper from '../../../framework/base/common/helpers/PathHelper';
import type { ImageModel } from '../../../framework/base/common/models/entities/image/base/ImageModel';
import ApiRoutePaths from '../../constants/path/ApiRoutePaths';
import type { AdvertDetailApiModel } from '../../models/api/advert/AdvertDetailApiModel';
import FancyInput, { type MasonryHandle } from '../shared/elements/grid/FancyInput';
import LoadingFadeContent from '../shared/elements/LoadingFadeContent';
// import '~react-image-lightbox/style.css';

const MasonryLibraryLoader = loadable.lib(() => import('masonry-layout'), { ssr: false });

interface AdvertDetailModalImagesProps {
  item: AdvertDetailApiModel;
  images: ImageModel[];
}

const AdvertDetailModalImages: React.FC<AdvertDetailModalImagesProps> = ({ item, images }: AdvertDetailModalImagesProps) => {

  // const [photoIndex, setPhotoIndex] = useState<number>();

  const gridHtmlDivElementRef = useRef<HTMLDivElement>(null);

  const masonryHandleRef = React.createRef<MasonryHandle>();

  const handleOnImageLoaded = () => {
    masonryHandleRef.current?.ensure();
  };

  // const mainPhoto = photoIndex ? images[photoIndex] : undefined;
  // const nextIndex = photoIndex ? (photoIndex + 1) % images.length : undefined;
  // const nextPhoto = nextIndex ? images[nextIndex] : undefined;
  // const prevIndex = photoIndex ? (photoIndex + images.length - 1) % images.length : undefined;
  // const prevPhoto = prevIndex ? images[prevIndex] : undefined;

  return !item.images
    ? null
    : (
      <>
        <div className='row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 grid' ref={gridHtmlDivElementRef}>
          {images?.map((image, index) => (
            <div
              key={index}
              className='col grid-item'
            >
              <a
                href={PathHelper.getEntityImageUrl(ApiRoutePaths.IMAGES_PART_ADVERT, item.id, image, EntityImageSizeEnum.Source)}
                target='_blank'
                rel='noreferrer'
              // onClick={(e) => {
              //   e.preventDefault();
              //   // setPhotoIndex(index);
              // }}
              >
                <img
                  className='w-100 mb-3'
                  src={PathHelper.getEntityImageUrl(ApiRoutePaths.IMAGES_PART_ADVERT, item.id, image, EntityImageSizeEnum.Medium600)}
                  alt=''
                  onLoad={handleOnImageLoaded}
                // width={PathHelper.getImageWidth(AdvertImageSizeEnum.Medium600)}
                // height={PathHelper.getImageResizedHeight(image, AdvertImageSizeEnum.Medium600)}
                />
              </a>
            </div>
          ))}
        </div>
        {/* {photoIndex !== undefined &&
          mainPhoto !== undefined &&
          nextIndex !== undefined &&
          nextPhoto !== undefined &&
          prevIndex !== undefined &&
          prevPhoto !== undefined && (
            <Lightbox
              mainSrc={PathHelper.getEntityImageUrl(ApiRoutePaths.IMAGES_ADVERT, item.id, mainPhoto, EntityImageSizeEnum.Source)}
              nextSrc={PathHelper.getEntityImageUrl(ApiRoutePaths.IMAGES_ADVERT, item.id, nextPhoto, EntityImageSizeEnum.Source)}
              prevSrc={PathHelper.getEntityImageUrl(ApiRoutePaths.IMAGES_ADVERT, item.id, prevPhoto, EntityImageSizeEnum.Source)}
              onCloseRequest={() => setPhotoIndex(undefined)}
              onMovePrevRequest={() => setPhotoIndex(prevIndex)}
              onMoveNextRequest={() => setPhotoIndex(nextIndex)} 
            />
          )} */}
        {images.length > 0 && (
          <MasonryLibraryLoader
            fallback={(
              <LoadingFadeContent isLoading={true} />
            )}
          >
            {(leafletMapLibrary) => {
              return (
                <FancyInput
                  lib={leafletMapLibrary}
                  gridHtmlDivElementRef={gridHtmlDivElementRef}
                  ref={masonryHandleRef}
                />
              );
            }}
          </MasonryLibraryLoader>
        )}
      </>
    );
};

export default AdvertDetailModalImages;
