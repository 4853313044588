import ErrorTypeEnum from '../../constants/enums/ErrorTypeEnum';
import BaseApplicationError from './base/BaseApplicationError';
import type { FormErrorPayloadModel } from './payloads/FormErrorPayloadModel';

class FormError<Values> extends BaseApplicationError {

  public readonly type = ErrorTypeEnum.FormError;

  public payload: FormErrorPayloadModel<Values>;

  constructor(payload: FormErrorPayloadModel<Values>, message?: string) {
    super(message);

    this.payload = payload;
  }
}

export default FormError;
