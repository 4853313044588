import type EntityStateActionTypeEnum from '../../../../../constants/enums/EntityStateActionTypeEnum';
import createEntityActionNameByMode from '../base/createEntityActionNameByMode';

const createEntitiesListActionName = (
  entityType: string, // EntityTypeEnum,
  entityStateActionType: EntityStateActionTypeEnum,
  namespace: string
): string => {
  return createEntityActionNameByMode('list', entityType, entityStateActionType, namespace);
};

export default createEntitiesListActionName;
