import React, { type ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import type UserRoleEnum from '../../../constants/UserRoleEnum';
import useUser from '../../../hooks/useUser';
import type { IUserAwareState } from '../../../store/modules/app/models/IUserAwareState';
import Loading from '../../shared/elements/Loading';

interface PrivateRouteProps {
  passRole?: UserRoleEnum;
  children: ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props: PrivateRouteProps): ReactElement | null => {

  const user = useUser();
  const isFetching = useSelector((state: IUserAwareState) => state.user.isFetching);

  return isFetching
    ? (
      <Loading />
    )
    : !user
      ? (
        <Navigate
          to={{
            pathname: '/signin',
          }}
        />
      )
      : !props.passRole || user.roles?.includes(props.passRole)
        ? (
          props.children
        )
        : (
          <Navigate to={{ pathname: '/' }} />
        );
};

export default PrivateRoute;
