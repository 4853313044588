import EntityStateActionTypeEnum from '../../../../../constants/enums/EntityStateActionTypeEnum';
import type { BaseEntitiesQuery } from '../../../../../models/queries/base/BaseEntitiesQuery';
import type { FSAP } from '../../../base/fsa';
import type { BaseUserItemsRequestPayload } from '../../../models/payloads/base/BaseUserItemsRequestPayload';
import createEntitiesListActionName from './createEntitiesListActionName';

const createUsersEntitiesListRequestAction = <TQuery extends BaseEntitiesQuery>(
  jwt: string,
  entityType: string, // EntityTypeEnum,
  query: TQuery,
  namespace: string
): FSAP<BaseUserItemsRequestPayload<TQuery>> => ({
  type: createEntitiesListActionName(entityType, EntityStateActionTypeEnum.Request, namespace),
  payload: {
    query,
    jwt: jwt,
  },
});

export default createUsersEntitiesListRequestAction;
