import { produce } from 'immer';
import type { Action } from 'redux';
import EntityStateActionTypeEnum from '../../../../constants/enums/EntityStateActionTypeEnum';
import type { BaseEntityDetailApiModel } from '../../../../models/api/base/BaseEntityDetailApiModel';
import type { BaseEntitiesQuery } from '../../../../models/queries/base/BaseEntitiesQuery';
import type { FSAP } from '../../../actions/base/fsa';
import createEntityDetailActionName from '../../../actions/factories/entity/detail/createEntityDetailActionName';
import type { BaseFailedPayload } from '../../../actions/models/payloads/base/BaseFailedPayload';
import type { BaseItemQueryPayload } from '../../../actions/models/payloads/base/BaseItemQueryPayload';
import type { BaseItemRequestPayload } from '../../../actions/models/payloads/base/BaseItemRequestPayload';
import type { BaseSucceededPayload } from '../../../actions/models/payloads/base/BaseSucceededPayload';
import type { EntityDetailState } from '../../../actions/models/state/entities/EntityDetailState';

const entityDetailReducerFactory = <
  TModel extends BaseEntityDetailApiModel,
  TQuery extends BaseEntitiesQuery = BaseEntitiesQuery
>(
  entityType: string, // EntityTypeEnum,
  namespace: string
): ((base: EntityDetailState<TModel, TQuery> | undefined, action: Action) => EntityDetailState<TModel, TQuery>) => {

  return (base: EntityDetailState<TModel, TQuery> | undefined, action: Action): EntityDetailState<TModel, TQuery> => {
    const nextState = produce<EntityDetailState<TModel, TQuery>, EntityDetailState<TModel, TQuery>>(base ? base : {}, draft => {
      if (!draft) {
        draft = {};
      }
      switch (action.type) {
        case createEntityDetailActionName(entityType, EntityStateActionTypeEnum.Request, namespace):
          draft.isFetching = true;
          draft.id = (action as FSAP<BaseItemRequestPayload>).payload.id;
          draft.query = undefined;
          break;
        case createEntityDetailActionName(entityType, EntityStateActionTypeEnum.Query, namespace):
          draft.isFetching = true;
          draft.id = undefined;
          draft.query = (action as FSAP<BaseItemQueryPayload<TQuery>>).payload.query;
          break;
        case createEntityDetailActionName(entityType, EntityStateActionTypeEnum.Succeeded, namespace):
          draft.isFetching = undefined;
          draft.data = (action as FSAP<BaseSucceededPayload<TModel>>).payload.data;
          draft.error = undefined;
          break;
        case createEntityDetailActionName(entityType, EntityStateActionTypeEnum.Failed, namespace):
          draft.isFetching = undefined;
          draft.data = undefined;
          draft.error = (action as FSAP<BaseFailedPayload>).payload.error;
          break;
        case createEntityDetailActionName(entityType, EntityStateActionTypeEnum.Clear, namespace):
          draft.isFetching = undefined;
          draft.data = undefined;
          draft.error = undefined;
          draft.id = undefined;
          draft.query = undefined;
          break;
      }
    });

    return nextState;
  };
};

export default entityDetailReducerFactory;
