import React from 'react';
import '../../client/styles/main.scss';
import Footer from './layout/Footer';
import Header from './layout/Header';
import Main from './layout/Main';
import Meta from './layout/Meta';

const AppComponent: React.FC = () => {
  return (
    <>
      <Meta />
      <Header />
      <Main />
      <Footer />
    </>
  );
};

export default AppComponent;
