import type EntityStateActionTypeEnum from '../../../../../constants/enums/EntityStateActionTypeEnum';
import createEntityActionNameByMode from '../base/createEntityActionNameByMode';

const createEntityDetailActionName = (
  entityType: string, // EntityTypeEnum,
  entityStateActionType: EntityStateActionTypeEnum,
  namespace: string
): string => {
  return createEntityActionNameByMode('detail', entityType, entityStateActionType, namespace);
};

export default createEntityDetailActionName;
