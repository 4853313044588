import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';
import type { AdvertListItemApiModel } from '../../../models/api/advert/AdvertListItemApiModel';
import AdvertsListItemImage from '../../shared/partials/advert/AdvertsListItemImage';

interface AdvertsListPageItemProps {
  item: AdvertListItemApiModel;
  index: number;
  onImageLoaded: () => void;
}

const AdvertsListPageItem: React.FC<AdvertsListPageItemProps> = ({ item, index, onImageLoaded }: AdvertsListPageItemProps) => {
  return (
    <div className={classNames('pb-2 mt-0 grid-item')}>
      <div className='card position-relative'>
        <AdvertsListItemImage
          advert={item}
          image={item.image}
          onImageLoaded={onImageLoaded}
          forceDisplay={index < 10}
        />
        <div className='card-body'>
          <h2 className='card-title h5'>
            <NavLink
              to={`/ad/${item.id}`}
              className='nounderline stretched-link'
              title=''
            >
              {item.title}
            </NavLink>
          </h2>
          {/* {longInfo.length > 0 && (
            <h3 className='pb-2' style={{ fontSize: '1em', fontWeight: 'normal' }}>
              {longInfo.join(', ')}
            </h3>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default AdvertsListPageItem;
