import loadable from '@loadable/component';
import React from 'react';
import type { RouteObject } from 'react-router-dom';
import UserRoleEnum from '../../../constants/UserRoleEnum';
import BaseRendererRoutePaths from '../../../constants/path/BaseRendererRoutePaths';
import BaseWebUrlPaths from '../../../constants/path/BaseWebUrlPaths';
import PrivateRoute from './PrivateRoute';

const AuthPage = loadable(() => import(/* webpackChunkName: "page-auth" */'../../../../common/components/pages/auth/AuthPage'));
const ContactPage = loadable(() => import(/* webpackChunkName: "page-content-help" */'../../../../common/components/pages/common/ContactPage'));
const ForgottenPasswordPage = loadable(() => import(/* webpackChunkName: "page-forgotten-password" */'../../../../common/components/pages/common/ForgottenPasswordPage'));
const SignInPage = loadable(() => import(/* webpackChunkName: "page-sign-in" */'../../../../common/components/pages/auth/SignInPage'));
const SignUpPage = loadable(() => import(/* webpackChunkName: "page-sign-up" */'../../../../common/components/pages/auth/SignUpPage'));
const RemoveAccountPage = loadable(() => import(/* webpackChunkName: "page-remove-account" */'../../../../common/components/pages/common/RemoveAccountPage'));
const ResetPasswordPage = loadable(() => import(/* webpackChunkName: "page-reset-password" */'../../../../common/components/pages/common/ResetPasswordPage'));
const ErrorNotFoundPage = loadable(() => import(/* webpackChunkName: "page-error-not-found" */'../../pages/common/ErrorNotFoundPage'));

interface BaseAppRouterOptions {
  appRoutes: RouteObject[],
  aboutPageElement: React.ReactNode;
  adminPage: React.FC;
  confirmEmailPagePageElement: React.ReactNode;
  privacyPageElement: React.ReactNode;
  profilePage: React.FC;
  rodoPageElement: React.ReactNode;
  sitemapPageElement: React.ReactNode;
  termsPageElement: React.ReactNode;
}

function createBaseAppRouterConfig({
  appRoutes,
  aboutPageElement,
  adminPage,
  confirmEmailPagePageElement,
  privacyPageElement,
  profilePage,
  rodoPageElement,
  sitemapPageElement,
  termsPageElement,
}: BaseAppRouterOptions): RouteObject[] {

  const AdminPage = adminPage;
  const ProfilePage = profilePage;

  const routesConfig: RouteObject[] = [
    ...appRoutes,
    { path: BaseWebUrlPaths.SIGNIN, element: <SignInPage /> },
    { path: BaseWebUrlPaths.SIGNUP, element: <SignUpPage /> },
    { path: BaseWebUrlPaths.FORGOTTEN_PASSWORD, element: <ForgottenPasswordPage /> },
    { path: BaseWebUrlPaths.ABOUT, element: aboutPageElement },
    { path: BaseWebUrlPaths.TERMS, element: termsPageElement },
    { path: BaseWebUrlPaths.PRIVACY, element: privacyPageElement },
    { path: BaseWebUrlPaths.RODO, element: rodoPageElement },
    { path: BaseWebUrlPaths.CONTACT, element: <ContactPage /> },
    { path: BaseRendererRoutePaths.RESET_PASSWORD, element: <ResetPasswordPage /> },
    { path: BaseRendererRoutePaths.CONFIRM_EMAIL, element: confirmEmailPagePageElement },
    { path: BaseRendererRoutePaths.REMOVE_ACCOUNT, element: <RemoveAccountPage /> },
    { path: `${BaseWebUrlPaths.SITEMAP}/*`, element: sitemapPageElement },
    { path: `${BaseRendererRoutePaths.OAUTH}/:provider/callback/:source`, element: <AuthPage /> },
    {
      path: `${BaseRendererRoutePaths.PROFILE}/*`,
      element: (
        <PrivateRoute>
          <ProfilePage />
        </PrivateRoute>
      )
    },
    {
      path: `${BaseRendererRoutePaths.ADMIN}/*`,
      element: (
        <PrivateRoute passRole={UserRoleEnum.Admin}>
          <AdminPage />
        </PrivateRoute>
      )
    },
    { path: '*', element: <ErrorNotFoundPage /> },
  ];

  return routesConfig;
}

export type {
  BaseAppRouterOptions
};

export default createBaseAppRouterConfig;
