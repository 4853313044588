import BaseUserEntityDetailPageContentService from '../../../../framework/base/common/services/content/entity/base/BaseUserEntityDetailPageContentService';
import type { AdvertDetailApiModel } from '../../../models/api/advert/AdvertDetailApiModel';
import type { AdvertSharedPropertiesApiModel } from '../../../models/api/advert/AdvertSharedPropertiesApiModel';

const TitleShelterMatrix: Record<string, string> = {
};

class AdvertDetailPageContentService extends BaseUserEntityDetailPageContentService<AdvertSharedPropertiesApiModel, AdvertDetailApiModel> {

  public static getAdvertTitle = (item: { category: string; }): string => {
    return AdvertDetailPageContentService.getShelterAdvertTitleByParams(item.category);
  };

  public static getShelterAdvertTitleByParams = (category: string): string => {
    const byType = TitleShelterMatrix[category];

    return byType ?? '';
  };

  public static getAdvertTitleWithLocation = (item: AdvertDetailApiModel): string => {
    const parts = [
      AdvertDetailPageContentService.getAdvertTitle(item),
    ];

    return parts.filter((part) => !!part).join(', ');
  };

  public override getDetailHeadTitle = (item: AdvertDetailApiModel): string => {
    return item.id;
  };

  public override getDetailHeadDescription = (item: AdvertDetailApiModel): string => {
    return item.title;
  };

  public getEntityLabel = (item: AdvertDetailApiModel): string => {
    return item.id;
  };
}

export default AdvertDetailPageContentService;

