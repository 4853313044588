import BaseEntitiesUrlQueryMapper from '../../../framework/base/common/helpers/mappers/base/BaseEntitiesUrlQueryMapper';
import type { BaseEntitiesQuery } from '../../../framework/base/common/models/queries/base/BaseEntitiesQuery';
import { TAXONOMY_CATEGORIES } from '../../constants/CommonConstants';
import FilterCodeEnum from '../../constants/enums/FilterCodeEnum';
import type { AdvertsQueryFilters } from '../../models/entities/advert/queries/AdvertsQueryFilters';
import type { AdvertsUrlQuery } from '../../models/entities/advert/queries/AdvertsUrlQuery';

class AdvertsUrlQueryMapper extends BaseEntitiesUrlQueryMapper<
  AdvertsUrlQuery,
  AdvertsQueryFilters,
  BaseEntitiesQuery<AdvertsQueryFilters>
> {

  public override mapUrlQueryToQuery(urlQuery: AdvertsUrlQuery): Readonly<BaseEntitiesQuery<AdvertsQueryFilters>> {
    const query: BaseEntitiesQuery<AdvertsQueryFilters> = super.mapUrlQueryToQueryIntrinsic(urlQuery);

    return query;
  }

  public override mapQueryToUrlQuery(query: BaseEntitiesQuery<AdvertsQueryFilters>): AdvertsUrlQuery {
    const urlQuery: AdvertsUrlQuery = this.mapQueryToUrlQueryIntrinsic(query);

    return urlQuery;
  }

  protected override createEmptyQuery(): BaseEntitiesQuery<AdvertsQueryFilters> {
    const query: BaseEntitiesQuery<AdvertsQueryFilters> = {};

    return query;
  }

  protected override mapUrlQueryToQueryFilters(urlQuery: AdvertsUrlQuery): AdvertsQueryFilters {
    const queryFilters: AdvertsQueryFilters = this.mapUrlQueryToQueryFiltersIntrinsic(urlQuery);

    return queryFilters;
  }

  protected override mapUrlQueryToQueryFiltersIntrinsic(urlQuery: AdvertsUrlQuery): AdvertsQueryFilters {
    let queryFilters: AdvertsQueryFilters = super.mapUrlQueryToQueryFiltersIntrinsic(urlQuery);

    const category = urlQuery.category ? this.parseTaxonomyArrayFilterValue(TAXONOMY_CATEGORIES, urlQuery.category) : undefined;

    if (category != undefined) {
      if (queryFilters === undefined) {
        queryFilters = {};
      }

      if (category !== undefined) {
        queryFilters.category = category;
      }
    }

    return queryFilters;
  }

  protected override mapQueryToUrlQueryIntrinsic(query: Readonly<BaseEntitiesQuery<AdvertsQueryFilters>>): AdvertsUrlQuery {
    const urlQuery: AdvertsUrlQuery = super.mapQueryToUrlQueryIntrinsic(query);

    const queryFilters = query.filters;

    if (queryFilters) {
      urlQuery[FilterCodeEnum.Category] = queryFilters.category;
    }

    return urlQuery;
  }
}

export default AdvertsUrlQueryMapper;
