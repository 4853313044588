import { call, put, takeLatest, type CallEffect, type ForkEffect, type PutEffect } from 'redux-saga/effects';
import type BaseEntitiesSearchService from '../../../../../client/services/base/BaseEntitiesSearchService';
import EntityStateActionTypeEnum from '../../../../constants/enums/EntityStateActionTypeEnum';
import type { IEntitiesQueryMapper } from '../../../../helpers/mappers/interfaces/IEntitiesQueryMapper';
import type { BaseEntityDetailApiModel } from '../../../../models/api/base/BaseEntityDetailApiModel';
import type { BaseEntityListItemApiModel } from '../../../../models/api/base/BaseEntityListItemApiModel';
import type { BaseEntitiesQuery } from '../../../../models/queries/base/BaseEntitiesQuery';
import type { BaseEntitiesQueryFilters } from '../../../../models/queries/base/BaseEntitiesQueryFilters';
import type { ItemsListModel } from '../../../../models/system/pagination/ItemsListModel';
import type { FSAP } from '../../../actions/base/fsa';
import createEntitiesListActionName from '../../../actions/factories/entity/items/createEntitiesListActionName';
import createEntitiesListFailAction from '../../../actions/factories/entity/items/createEntitiesListFailAction';
import createEntitiesListPopulateAction from '../../../actions/factories/entity/items/createEntitiesListPopulateAction';
import type { BaseFailedPayload } from '../../../actions/models/payloads/base/BaseFailedPayload';
import type { BaseItemsRequestPayload } from '../../../actions/models/payloads/base/BaseItemsRequestPayload';
import type { BaseSucceededPayload } from '../../../actions/models/payloads/base/BaseSucceededPayload';

type EntityGenerator<TListItemModel extends BaseEntityListItemApiModel> = Generator<
  CallEffect<ItemsListModel<TListItemModel>> | PutEffect<FSAP<BaseSucceededPayload<ItemsListModel<TListItemModel>>>> | PutEffect<FSAP<BaseFailedPayload>>,
  void,
  ItemsListModel<TListItemModel>
>;

const createEntitiesList = <
  TDetailApiModel extends BaseEntityDetailApiModel,
  TQueryFilters extends BaseEntitiesQueryFilters,
  TQuery extends BaseEntitiesQuery<TQueryFilters>,
  TMapper extends IEntitiesQueryMapper<TQuery>,
  TListItemModel extends BaseEntityListItemApiModel,
  TService extends BaseEntitiesSearchService<
    TDetailApiModel,
    TQueryFilters,
    TQuery,
    TMapper,
    TListItemModel
  >
>(
  entityType: string, // EntityTypeEnum,
  ctor: new () => TService,
  namespace: string,
): ((action: FSAP<BaseItemsRequestPayload>) => EntityGenerator<TListItemModel>) => {

  function* entitiesList(action: FSAP<BaseItemsRequestPayload>): EntityGenerator<TListItemModel> {
    try {
      const service = new ctor();
      const result = yield call((query) => { return service.search(query); }, action.payload.query);
      yield put(createEntitiesListPopulateAction(entityType, result, namespace));
    } catch (error) {
      console.error(error);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yield put(createEntitiesListFailAction(entityType, error as any, namespace));
    }
  }

  return entitiesList;
};

const createEntitiesListSaga = <
  TDetailApiModel extends BaseEntityDetailApiModel,
  TQueryFilters extends BaseEntitiesQueryFilters,
  TQuery extends BaseEntitiesQuery<TQueryFilters>,
  TMapper extends IEntitiesQueryMapper<TQuery>,
  TListItemModel extends BaseEntityListItemApiModel,
  TService extends BaseEntitiesSearchService<
    TDetailApiModel,
    TQueryFilters,
    TQuery,
    TMapper,
    TListItemModel
  >
>(
  entityType: string, // EntityTypeEnum,
  ctor: new () => TService,
  namespace: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (() => Iterator<any>) => {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function* entitiesSaga(): Generator<ForkEffect<never>, void, any> {
    yield takeLatest(
      createEntitiesListActionName(entityType, EntityStateActionTypeEnum.Request, namespace),
      createEntitiesList<
        TDetailApiModel,
        TQueryFilters,
        TQuery,
        TMapper,
        TListItemModel,
        TService
      >(entityType, ctor, namespace)
    );
  }

  return entitiesSaga;
};

export default createEntitiesListSaga;
