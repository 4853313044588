import { combineReducers, Reducer, type AnyAction, type ReducersMapObject } from 'redux';
import type { EntitiesListState } from '../../../../framework/base/common/store/actions/models/state/entities/EntitiesListState';
import type { EntityDetailState } from '../../../../framework/base/common/store/actions/models/state/entities/EntityDetailState';
import entitiesListReducerFactory from '../../../../framework/base/common/store/reducers/factories/entity/entitiesListReducerFactory';
import entityDetailReducerFactory from '../../../../framework/base/common/store/reducers/factories/entity/entityDetailReducerFactory';
import EntityTypeEnum from '../../../constants/enums/EntityTypeEnum';
import StoreNamespaceEnum from '../../../constants/enums/StoreNamespaceEnum';
import type { AdvertDetailApiModel } from '../../../models/api/advert/AdvertDetailApiModel';
import type { AdvertListItemApiModel } from '../../../models/api/advert/AdvertListItemApiModel';
import type { AdvertsQuery } from '../../../models/entities/advert/queries/AdvertsQuery';
import type { AdvertsState } from './models/AdvertsState';

const advertsItems: Reducer<EntitiesListState<AdvertListItemApiModel, AdvertsQuery> | undefined> = entitiesListReducerFactory<
  AdvertListItemApiModel,
  AdvertsQuery
>(EntityTypeEnum.Advert, StoreNamespaceEnum.Catalog);

const advertDetail: Reducer<EntityDetailState<AdvertDetailApiModel> | undefined> = entityDetailReducerFactory<
  AdvertDetailApiModel
>(EntityTypeEnum.Advert, StoreNamespaceEnum.Catalog);

const profileAdvertsReducersMap: ReducersMapObject<AdvertsState, AnyAction> = {
  items: advertsItems,
  detail: advertDetail,
};

const advertsReducer: Reducer<AdvertsState, AnyAction> = combineReducers(profileAdvertsReducersMap);

export default advertsReducer;
