import React from 'react';
import '../../../client/styles/components/layout/Header';
import MainNavigation from './header/MainNavigation';

const Header: React.FC = () => {
  return (
    <header>
      <MainNavigation />
    </header>
  );
};

export default Header;
