import type { ISagaModule } from 'redux-dynamic-modules-saga';
import AdvertsService from '../../../../client/services/entities/AdvertsService';
import createEntitiesListSaga from '../../../../framework/base/common/store/sagas/factories/entity/createEntitiesListSaga';
import createEntitySaga from '../../../../framework/base/common/store/sagas/factories/entity/createEntitySaga';
import EntityTypeEnum from '../../../constants/enums/EntityTypeEnum';
import StoreNamespaceEnum from '../../../constants/enums/StoreNamespaceEnum';
import advertsReducer from './advertsReducer';
import type { IAdvertsAwareState } from './models/IAdvertsAwareState';

const advertsModule: ISagaModule<IAdvertsAwareState> = {
  id: 'adverts',
  reducerMap: {
    adverts: advertsReducer,
  },
  sagas: [
    createEntitySaga(EntityTypeEnum.Advert, AdvertsService, StoreNamespaceEnum.Catalog),
    createEntitiesListSaga(EntityTypeEnum.Advert, AdvertsService, StoreNamespaceEnum.Catalog),
  ],
};

export default advertsModule;
