import type Taxonomy from '../../../framework/base/common/models/system/Taxonomy';
import { TAXONOMY_CATEGORY_DRAWING, TAXONOMY_CATEGORY_PAINTING, TAXONOMY_CATEGORY_SCENOGRAPHY, TAXONOMY_CATEGORY_SCULPTURE } from '../../constants/CommonConstants';
import { Labels } from '../../helpers/Content';

const categories: Taxonomy[] = [
  { code: TAXONOMY_CATEGORY_PAINTING, label: Labels.category[TAXONOMY_CATEGORY_PAINTING] ?? '' },
  { code: TAXONOMY_CATEGORY_SCULPTURE, label: Labels.category[TAXONOMY_CATEGORY_SCULPTURE] ?? '' },
  { code: TAXONOMY_CATEGORY_DRAWING, label: Labels.category[TAXONOMY_CATEGORY_DRAWING] ?? '' },
  { code: TAXONOMY_CATEGORY_SCENOGRAPHY, label: Labels.category[TAXONOMY_CATEGORY_SCENOGRAPHY] ?? '' },
];

class TaxonomyContentService {

  public static getCategories = (): Taxonomy[] => {
    return categories;
  };

  public static getCategoryCodes = (): string[] => {
    return categories.map(taxonomy => taxonomy.code);
  };
}

export default TaxonomyContentService;
