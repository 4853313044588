import { call, put, takeLatest, type CallEffect, type ForkEffect, type PutEffect } from 'redux-saga/effects';
import type BaseEntitiesSearchService from '../../../../../client/services/base/BaseEntitiesSearchService';
import type BaseEntitiesService from '../../../../../client/services/base/BaseEntitiesService';
import EntityStateActionTypeEnum from '../../../../constants/enums/EntityStateActionTypeEnum';
import type { IEntitiesQueryMapper } from '../../../../helpers/mappers/interfaces/IEntitiesQueryMapper';
import type { BaseEntityDetailApiModel } from '../../../../models/api/base/BaseEntityDetailApiModel';
import type { BaseEntityListItemApiModel } from '../../../../models/api/base/BaseEntityListItemApiModel';
import type { BaseEntitiesQuery } from '../../../../models/queries/base/BaseEntitiesQuery';
import type { BaseEntitiesQueryFilters } from '../../../../models/queries/base/BaseEntitiesQueryFilters';
import type { FSAP } from '../../../actions/base/fsa';
import createEntityDetailActionName from '../../../actions/factories/entity/detail/createEntityDetailActionName';
import createEntityDetailFailAction from '../../../actions/factories/entity/detail/createEntityDetailFailAction';
import createEntityDetailPopulateAction from '../../../actions/factories/entity/detail/createEntityDetailPopulateAction';
import type { BaseFailedPayload } from '../../../actions/models/payloads/base/BaseFailedPayload';
import type { BaseItemQueryPayload } from '../../../actions/models/payloads/base/BaseItemQueryPayload';
import type { BaseItemRequestPayload } from '../../../actions/models/payloads/base/BaseItemRequestPayload';
import type { BaseSucceededPayload } from '../../../actions/models/payloads/base/BaseSucceededPayload';

type EntityGenerator<TDetailModel extends BaseEntityDetailApiModel> = Generator<
  CallEffect<TDetailModel> | PutEffect<FSAP<BaseSucceededPayload<TDetailModel>>> | PutEffect<FSAP<BaseFailedPayload>>,
  void,
  TDetailModel
>;

const createEntityDetail = <
  TDetailModel extends BaseEntityDetailApiModel
>(
  entityType: string, // EntityTypeEnum,
  ctor: new () => BaseEntitiesService<TDetailModel>,
  namespace: string,
): ((action: FSAP<BaseItemRequestPayload>) => EntityGenerator<TDetailModel>) => {

  function* entityDetail(action: FSAP<BaseItemRequestPayload>): EntityGenerator<TDetailModel> {
    try {
      const service = new ctor();
      // const result = (yield call(service.getItem, action.payload.id));
      const result = (yield call((id) => { return service.getItem(id); }, action.payload.id));
      yield put(createEntityDetailPopulateAction(entityType, result, namespace));
    } catch (error) {
      console.error(error);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yield put(createEntityDetailFailAction(entityType, error as any, namespace));
    }
  }

  return entityDetail;
};

const createEntityDetailByQuery = <
  TDetailModel extends BaseEntityDetailApiModel,
  TQueryFilters extends BaseEntitiesQueryFilters,
  TQuery extends BaseEntitiesQuery<TQueryFilters>,
>(
  entityType: string, // EntityTypeEnum,
  ctor: new () => BaseEntitiesSearchService<TDetailModel, TQueryFilters, TQuery, IEntitiesQueryMapper<TQuery>, BaseEntityListItemApiModel>,
  namespace: string,
): ((action: FSAP<BaseItemQueryPayload<TQuery>>) => EntityGenerator<TDetailModel>) => {

  function* entityDetail(action: FSAP<BaseItemQueryPayload<TQuery>>): EntityGenerator<TDetailModel> {
    try {
      const service = new ctor();
      // const result = (yield call(service.getItem, action.payload.id));
      const result = (yield call((query) => { return service.getFindByQuery(query); }, action.payload.query));
      yield put(createEntityDetailPopulateAction(entityType, result, namespace));
    } catch (error) {
      console.error(error);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yield put(createEntityDetailFailAction(entityType, error as any, namespace));
    }
  }

  return entityDetail;
};

const createEntitySaga = <
  TDetailModel extends BaseEntityDetailApiModel,
  TQueryFilters extends BaseEntitiesQueryFilters,
  TQuery extends BaseEntitiesQuery<TQueryFilters>,
>(
  entityType: string, // EntityTypeEnum,
  ctor: new () => BaseEntitiesSearchService<TDetailModel, TQueryFilters, TQuery, IEntitiesQueryMapper<TQuery>, BaseEntityListItemApiModel>,
  namespace: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (() => Iterator<any>) => {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function* entitySaga(): Generator<ForkEffect<never>, void, any> {
    yield takeLatest(
      createEntityDetailActionName(entityType, EntityStateActionTypeEnum.Request, namespace),
      createEntityDetail<TDetailModel>(entityType, ctor, namespace)
    );
    yield takeLatest(
      createEntityDetailActionName(entityType, EntityStateActionTypeEnum.Query, namespace),
      createEntityDetailByQuery(entityType, ctor, namespace)
    );
  }

  return entitySaga;
};

export default createEntitySaga;
