import ApiRoutePaths from '../../../common/constants/path/ApiRoutePaths';
import AdvertsQueryMapper from '../../../common/helpers/mappers/AdvertsQueryMapper';
import type { AdvertCreateApiModel } from '../../../common/models/api/advert/AdvertCreateApiModel';
import type { AdvertDetailApiModel } from '../../../common/models/api/advert/AdvertDetailApiModel';
import type { AdvertListItemApiModel } from '../../../common/models/api/advert/AdvertListItemApiModel';
import type { AdvertsQuery } from '../../../common/models/entities/advert/queries/AdvertsQuery';
import type { AdvertsQueryFilters } from '../../../common/models/entities/advert/queries/AdvertsQueryFilters';
import BaseUsersEntitiesService from '../../../framework/base/client/services/base/BaseUsersEntitiesService';

class AdvertsService extends BaseUsersEntitiesService<
  AdvertCreateApiModel,
  AdvertDetailApiModel,
  AdvertsQueryFilters,
  AdvertsQuery,
  AdvertsQueryMapper,
  AdvertListItemApiModel
> {

  constructor(jwt?: string) {
    super(ApiRoutePaths.ADVERTS, new AdvertsQueryMapper(), jwt);
  }
}

export default AdvertsService;
