import { createSlice, type CaseReducer, type PayloadAction } from '@reduxjs/toolkit';
import type ErrorCodeEnum from '../../../constants/enums/ErrorCodeEnum';
import { APPLICATION_ERROR_MESSAGE } from '../../../helpers/BaseContent';
import type { BaseErrorPayload } from '../../../models/errors/payloads/base/BaseErrorPayload';
import type { NotificationsState } from './models/NotificationsState';

interface BaseMessagePayload {
  dontScroll?: boolean;
  keepAfterRedirect?: boolean;
}

interface SetErrorCodePayload extends BaseMessagePayload {
  errorCode: ErrorCodeEnum;
  errorPayload: BaseErrorPayload;
}

interface SetErrorMessagePayload extends BaseMessagePayload {
  errorMessage: string;
}

interface SetInfoMessagePayload extends BaseMessagePayload {
  infoMessage: string;
}

interface SetMessagePayload extends BaseMessagePayload {
  message: string;
}

const initialState: NotificationsState = {
  message: undefined,
  errorCode: undefined,
  errorMessage: undefined,
  infoMessage: undefined,
  errorPayload: undefined,
  counter: undefined,
  dontScroll: undefined,
  keepAfterRedirect: undefined
};

const clearMessagesReducer: CaseReducer<NotificationsState> = () => {
  return {
    ...initialState,
  };
};

const clearKeepAfterRedirectReducer: CaseReducer<NotificationsState> = (state) => {
  state.keepAfterRedirect = undefined;
};

const setApplicationErrorMessageReducer: CaseReducer<NotificationsState, PayloadAction<BaseMessagePayload | undefined>> = (state, action) => {
  return {
    ...initialState,
    errorMessage: APPLICATION_ERROR_MESSAGE,
    counter: state.counter ? ++state.counter : 1,
    dontScroll: action.payload?.dontScroll,
    keepAfterRedirect: action.payload?.keepAfterRedirect,
  };
};

const setErrorCodeReducer: CaseReducer<NotificationsState, PayloadAction<SetErrorCodePayload>> = (state, action) => {
  return {
    ...initialState,
    errorCode: action.payload.errorCode,
    errorPayload: action.payload.errorPayload,
    counter: state.counter ? ++state.counter : 1,
    dontScroll: action.payload.dontScroll,
    keepAfterRedirect: action.payload.keepAfterRedirect,
  };
};

const setErrorMessageReducer: CaseReducer<NotificationsState, PayloadAction<SetErrorMessagePayload>> = (state, action) => {
  return {
    ...initialState,
    errorMessage: action.payload.errorMessage,
    counter: state.counter ? ++state.counter : 1,
    dontScroll: action.payload.dontScroll,
    keepAfterRedirect: action.payload.keepAfterRedirect,
  };
};

const setInfoMessageReducer: CaseReducer<NotificationsState, PayloadAction<SetInfoMessagePayload>> = (state, action) => {
  return {
    ...initialState,
    infoMessage: action.payload.infoMessage,
    counter: state.counter ? ++state.counter : 1,
    dontScroll: action.payload.dontScroll,
    keepAfterRedirect: action.payload.keepAfterRedirect,
  };
};

const setMessageReducer: CaseReducer<NotificationsState, PayloadAction<SetMessagePayload>> = (state, action) => {
  return {
    ...initialState,
    message: action.payload.message,
    counter: state.counter ? state.counter + 1 : 1,
    dontScroll: action.payload.dontScroll,
    keepAfterRedirect: action.payload.keepAfterRedirect,
  };
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setMessage: setMessageReducer,
    setInfoMessage: setInfoMessageReducer,
    setErrorMessage: setErrorMessageReducer,
    setErrorCode: setErrorCodeReducer,
    setApplicationErrorMessage: setApplicationErrorMessageReducer,
    clearMessages: clearMessagesReducer,
    clearKeepAfterRedirect: clearKeepAfterRedirectReducer,
  },
});

const {
  clearKeepAfterRedirect,
  clearMessages,
  setApplicationErrorMessage,
  setErrorCode,
  setErrorMessage,
  setInfoMessage,
  setMessage,
} = notificationsSlice.actions;

const notificationsReducer = notificationsSlice.reducer;

export type {
  BaseMessagePayload,
  SetErrorCodePayload,
  SetErrorMessagePayload,
  SetInfoMessagePayload,
  SetMessagePayload
};

export {
  clearKeepAfterRedirect,
  clearMessages,
  setApplicationErrorMessage,
  setErrorCode,
  setErrorMessage,
  setInfoMessage,
  setMessage
};

export default notificationsReducer;
