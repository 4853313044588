class BaseRendererRoutePaths {
  static ADMIN = '/admin';
  static CONFIRM_EMAIL = '/confirm-email';
  static HOME = '/';
  static IMAGES = '/images';
  static IMAGES_TMP = '/tmp';
  static OAUTH = '/auth';
  static PROFILE = '/profile';
  static PROFILE_SOCIAL_APPLICATIONS = '/applications';
  static PROFILE_LOGINS = '/logins';
  static REMOVE_ACCOUNT = '/remove-account';
  static RESET_PASSWORD = '/reset-password';
}

export default BaseRendererRoutePaths;
