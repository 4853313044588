import ErrorTypeEnum from '../../constants/enums/ErrorTypeEnum';
import type OAuthErrorCodeEnum from '../../constants/enums/OAuthErrorCodeEnum';

class OAuthError extends Error {

  public readonly type = ErrorTypeEnum.OAuthError;

  code: OAuthErrorCodeEnum;
  email?: string | undefined;

  constructor(code: OAuthErrorCodeEnum, email?: string, message?: string) {
    super(message); // 'Error' breaks prototype chain here

    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain

    this.code = code;
    this.email = email;
  }
}

export default OAuthError;
