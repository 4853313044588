import BaseApiRoutePaths from '../../common/constants/path/BaseApiRoutePaths';
import type { SignInResult } from '../../common/models/auth/SignInResult';
import BaseGetService from './base/BaseGetService';

class AuthRetrieveService extends BaseGetService {

  constructor(jwt: string) {
    super('/auth', jwt);
  }

  public retrieve = async (): Promise<SignInResult> => {
    return await this.sendGet<SignInResult>(BaseApiRoutePaths.AUTH_RETRIEVE);
  };
}

export default AuthRetrieveService;
