import loadable from '@loadable/component';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import AdvertsService from '../../../../client/services/entities/AdvertsService';
import DynamicModuleLoaderWrapper from '../../../../framework/base/common/components/shared/elements/DynamicModuleLoaderWrapper';
import useUrlGenerator from '../../../../framework/base/common/hooks/useUrlGenerator';
import type { BaseEntitiesQuery } from '../../../../framework/base/common/models/queries/base/BaseEntitiesQuery';
import EntitiesListFromState from '../../../../framework/base/common/modules/shared/components/pages/entities/EntitiesListFromState';
import createEntitiesListSelector from '../../../../framework/base/common/store/selectors/helpers/createEntitiesListSelector';
import EntityTypeEnum from '../../../constants/enums/EntityTypeEnum';
import StoreNamespaceEnum from '../../../constants/enums/StoreNamespaceEnum';
import AdvertsUrlQueryMapper from '../../../helpers/mappers/AdvertsUrlQueryMapper';
import type { AdvertsQueryFilters } from '../../../models/entities/advert/queries/AdvertsQueryFilters';
import TaxonomyContentService from '../../../services/content/TaxonomyContentService';
import advertsModule from '../../../store/modules/adverts/advertsModule';
import type { IAdvertsAwareState } from '../../../store/modules/adverts/models/IAdvertsAwareState';
import LoadingFadeContent from '../../shared/elements/LoadingFadeContent';
import FancyInput, { MasonryHandle } from '../../shared/elements/grid/FancyInput';
import './AdvertsListPage.scss';
import AdvertsListPageItem from './AdvertsListPageItem';

const MasonryLibraryLoader = loadable.lib(() => import('masonry-layout'), { ssr: false });

// const sorts = {
//   '': 'Najnowsze',
//   'created': 'Najstarsze',
//   'title': 'Tytuł',
//   'title-desc': 'Tytuł malejąco',
// };

interface AdvertsListProps {
  categoryCode: string;
}

const AdvertsListPage: React.FC<AdvertsListProps> = ({ categoryCode }: AdvertsListProps) => {

  const contentNoEntitiesMessage = useMemo(
    () => TaxonomyContentService.getCategories().find((category) => category.code === categoryCode)?.label,
    [categoryCode]
  );

  const urlGenerator = useUrlGenerator<BaseEntitiesQuery<AdvertsQueryFilters>, AdvertsUrlQueryMapper>(AdvertsUrlQueryMapper);

  const gridHtmlDivElementRef = useRef<HTMLDivElement>(null);

  const masonryHandleRef = React.createRef<MasonryHandle>();

  const handleOnImageLoaded = () => {
    masonryHandleRef.current?.ensure();
  };

  const modifyQuery = useCallback((query: BaseEntitiesQuery<AdvertsQueryFilters>): BaseEntitiesQuery<AdvertsQueryFilters> => {
    const result: BaseEntitiesQuery<AdvertsQueryFilters> = {
      ...query,
      filters: {
        ...query.filters,
        category: [categoryCode],
      }
    };

    return result;
  }, [categoryCode]);


  useEffect(() => {
    masonryHandleRef.current?.destroy();
  }, [categoryCode, masonryHandleRef]);

  return (
    <DynamicModuleLoaderWrapper modules={[advertsModule]}>
      <main className='container-fluid position-relative'>
        <EntitiesListFromState
          entityType={EntityTypeEnum.Advert}
          namespace={StoreNamespaceEnum.Catalog}
          selector={createEntitiesListSelector((state: IAdvertsAwareState) => state?.adverts?.items)}
          serviceCtor={AdvertsService}
          urlGenerator={urlGenerator}
          modifyQuery={modifyQuery}
          content={{
            noEntities: `Brak prac w kategorii ${contentNoEntitiesMessage}.`,
          }}
          sorts={{
            'title': 'Tytuł',
            'title-desc': 'Tytuł malejąco',
          }}
          reloadRef={undefined}
        >
          {(items) => {
            return (
              <>
                <div className='row g-2 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 grid mt-2 ' ref={gridHtmlDivElementRef}>
                  {items.map((item, index) => {
                    return (
                      <AdvertsListPageItem
                        key={item.id}
                        index={index}
                        item={item}
                        onImageLoaded={handleOnImageLoaded}
                      />
                    );
                  })}
                </div>
                <MasonryLibraryLoader
                  fallback={(
                    <LoadingFadeContent isLoading={true} />
                  )}
                >
                  {(leafletMapLibrary) => {
                    return (
                      <FancyInput
                        lib={leafletMapLibrary}
                        gridHtmlDivElementRef={gridHtmlDivElementRef}
                        ref={masonryHandleRef}
                      />
                    );
                  }}
                </MasonryLibraryLoader>
              </>
            );
          }}
        </EntitiesListFromState>
      </main>
    </DynamicModuleLoaderWrapper>
  );
};

export default AdvertsListPage;
