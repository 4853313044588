import React, { useEffect, type ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../../components/shared/elements/Loading';
import ErrorTypeEnum from '../../../../../constants/enums/ErrorTypeEnum';
import useErrorHandler from '../../../../../hooks/useErrorHandler';
import type { BaseEntityDetailApiModel } from '../../../../../models/api/base/BaseEntityDetailApiModel';
import type { BaseEntitiesQuery } from '../../../../../models/queries/base/BaseEntitiesQuery';
import createEntityDetailClearAction from '../../../../../store/actions/factories/entity/detail/createEntityDetailClearAction';
import createEntityDetailQueryAction from '../../../../../store/actions/factories/entity/detail/createEntityDetailQueryAction';
import createEntityDetailRequestAction from '../../../../../store/actions/factories/entity/detail/createEntityDetailRequestAction';
import { setShowPageNotFound404 } from '../../../../../store/modules/app/commonReducer';
import type { ICommonAwareState } from '../../../../../store/modules/app/models/ICommonAwareState';
import type { EntitiesDetailSelector } from '../../../../../store/selectors/EntitiesDetailSelector';

interface EntityDetailFromStatePropsIdOnly {
  id: string;
  query?: never;
}

interface EntityDetailFromStatePropsQueryOnly<TQuery extends BaseEntitiesQuery = BaseEntitiesQuery> {
  id?: never;
  query: TQuery;
}

type EntityDetailFromStatePropsIdOrQuery<TQuery extends BaseEntitiesQuery = BaseEntitiesQuery> =
  EntityDetailFromStatePropsIdOnly |
  EntityDetailFromStatePropsQueryOnly<TQuery>;

type EntityDetailFromStateProps<
  TModel extends BaseEntityDetailApiModel,
  TState,
  TQuery extends BaseEntitiesQuery = BaseEntitiesQuery,
> = EntityDetailFromStatePropsIdOrQuery<TQuery> & {
  entityType: string;
  selector: EntitiesDetailSelector<TModel, TState, TQuery>;
  namespace: string;
  render: (item: TModel) => ReactNode;
};

const EntityDetailFromState: <
  TModel extends BaseEntityDetailApiModel,
  TState,
  TQuery extends BaseEntitiesQuery = BaseEntitiesQuery,
>({
  entityType,
  id,
  query,
  selector,
  namespace,
  render
}: EntityDetailFromStateProps<TModel, TState, TQuery>)
  => React.ReactElement<EntityDetailFromStateProps<TModel, TState, TQuery>> = <
    TIntrinsicModel extends BaseEntityDetailApiModel,
    TIntrinsicState,
    TIntrinsicQuery extends BaseEntitiesQuery = BaseEntitiesQuery,
  >({
    entityType,
    id,
    query,
    selector,
    namespace,
    render
  }: EntityDetailFromStateProps<TIntrinsicModel, TIntrinsicState, TIntrinsicQuery>): React.ReactElement => {

    const isLoading = !!useSelector(selector.isLoading);
    const item = useSelector(selector.data);
    const error = useSelector(selector.error);
    const stateId = useSelector(selector.id);
    const stateQuery = useSelector(selector.query);
    const showPageNotFound404 = useSelector((state: ICommonAwareState) => state.common.showPageNotFound404);

    const dispatch = useDispatch();

    // const { id } = useParams<EntityDetailModalSsrParams>();

    useEffect(() => {
      if (id) {
        if (stateId != id) {
          dispatch(createEntityDetailRequestAction(entityType, id, namespace));
        }
      }
    }, [dispatch, entityType, id, namespace, stateId]);

    useEffect(() => {
      if (query) {
        if (JSON.stringify(stateQuery) != JSON.stringify(query)) {
          dispatch(createEntityDetailQueryAction(entityType, query, namespace));
        }
      }
    }, [dispatch, entityType, namespace, query, stateQuery]);

    useEffect(() => (): void => {
      dispatch(createEntityDetailClearAction(entityType, namespace));
    }, [dispatch, entityType, namespace]);

    const errorHandler = useErrorHandler();

    useEffect(() => {
      if (error?.type === ErrorTypeEnum.NotFound404) {
        if (!showPageNotFound404) {
          dispatch(setShowPageNotFound404(true));
        }
      } else if (showPageNotFound404) {
        dispatch(setShowPageNotFound404(false));
      }
    }, [dispatch, error, showPageNotFound404]);

    useEffect(() => {
      if (error && error.type !== ErrorTypeEnum.NotFound404) {
        errorHandler(error);
      }
    }, [errorHandler, error]);

    return (
      <Loading isLoading={isLoading}>
        {(): React.ReactNode => item
          ? (
            <>
              {render(item)}
            </>
          )
          : null}
      </Loading>
    );
  };

export type {
  EntityDetailFromStatePropsIdOrQuery
};

export default EntityDetailFromState;
