import { createSlice, type AnyAction, type CaseReducer, type PayloadAction } from '@reduxjs/toolkit';
import type { AuthenticatedUserApiModel } from '../../../models/api/user/AuthenticatedUserApiModel';
import type { SignInResult } from '../../../models/auth/SignInResult';
import type { UserState } from './models/UserState';

interface RetrievePayload {
  jwt: string;
}

interface RetrieveSucceededPayload {
  user: AuthenticatedUserApiModel;
  jwt: string;
}

const initialState: UserState = {};

const authenticateReducer: CaseReducer<UserState, PayloadAction<SignInResult>> = (state, action) => {
  state.user = action.payload.user;
  state.jwt = action.payload.jwt;
};

const retrieveReducer: CaseReducer<UserState, PayloadAction<RetrievePayload>> = (state) => {
  state.isFetching = true;
};

const retrieveSucceededReducer: CaseReducer<UserState, PayloadAction<RetrieveSucceededPayload>> = (state, action) => {
  state.isFetching = undefined;
  state.user = action.payload.user;
  state.jwt = action.payload.jwt;
};

const retrieveFailedReducer: CaseReducer<UserState> = (state) => {
  state.isFetching = undefined;
  state.user = undefined;
  state.jwt = undefined;
};

const signoutReducer: CaseReducer<UserState, AnyAction> = (state) => {
  state.user = undefined;
  state.jwt = undefined;
};

const showWelcomeMessageReducer: CaseReducer<UserState, PayloadAction<true | undefined>> = (state, action) => {
  state.showWelcomeMessage = action.payload ? true : undefined;
};

const setUserReducer: CaseReducer<UserState, PayloadAction<RetrieveSucceededPayload>> = (state, action) => {
  state.user = action.payload.user;
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    authenticate: authenticateReducer,
    retrieve: retrieveReducer,
    retrieveSucceeded: retrieveSucceededReducer,
    retrieveFailed: retrieveFailedReducer,
    signout: signoutReducer,
    setUser: setUserReducer,
    showWelcomeMessage: showWelcomeMessageReducer,
  },
});

export type {
  RetrievePayload
};

const {
  authenticate: userAuthenticate,
  retrieve: userRetrieve,
  retrieveFailed: userRetrieveFailed,
  retrieveSucceeded: userRetrieveSucceeded,
  setUser: userSetUser,
  showWelcomeMessage: userShowWelcomeMessage,
  signout: userSignout,
} = userSlice.actions;

export {
  userAuthenticate,
  userRetrieve,
  userRetrieveFailed,
  userRetrieveSucceeded,
  userSetUser,
  userShowWelcomeMessage,
  userSignout
};

const userReducer = userSlice.reducer;

export default userReducer;
