import type { BaseUsersEntityDetailApiModel } from '../../../../models/api/base/user/BaseUsersEntityDetailApiModel';
import type { BaseUsersEntitySharedPropertiesApiModel } from '../../../../models/api/base/user/BaseUsersEntitySharedPropertiesApiModel';

abstract class BaseUserEntityDetailPageContentService<
  TSharedPropertiesModel extends BaseUsersEntitySharedPropertiesApiModel,
  TDetailModel extends TSharedPropertiesModel & BaseUsersEntityDetailApiModel
> {

  public abstract getEntityLabel: (item: TDetailModel) => string;

  public getEntityTitle = (item: TSharedPropertiesModel): string => {
    return item.title;
  };

  public getDetailHeadTitle = (item: TDetailModel): string => {
    return item.title;
  };

  public getDetailHeadDescription = (item: TDetailModel): string => {
    return item.description || item.title;
  };
}

export default BaseUserEntityDetailPageContentService;
