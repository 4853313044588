import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import ErrorTypeEnum from '../../../common/constants/enums/ErrorTypeEnum';
import ErrorMessageError from '../../../common/models/errors/ErrorMessageError';
import FormError from '../../../common/models/errors/FormError';
import InfoMessageError from '../../../common/models/errors/InfoMessageError';
import OAuthError from '../../../common/models/errors/OAuthError';
import type BaseApplicationError from '../../../common/models/errors/base/BaseApplicationError';
import BaseGetService from './BaseGetService';

abstract class BaseService extends BaseGetService {

  protected async sendPost<TResponse, TData = never>(routePath: string, data?: TData, config?: AxiosRequestConfig): Promise<TResponse> {
    try {
      const response = await axios.post<TData, AxiosResponse<TResponse>>(this.url(routePath), data, this.getConfig(config));
      return response.data;
    } catch (err) {
      throw this.parseError(err);
    }
  }

  protected async sendPut<TResponse, TData = never>(routePath: string, data?: TData, config?: AxiosRequestConfig): Promise<TResponse> {
    try {
      const response = await axios.put<TData, AxiosResponse<TResponse>>(this.url(routePath), data, this.getConfig(config));
      return response.data;
    } catch (err) {
      throw this.parseError(err);
    }
  }

  protected async sendDelete<T>(routePath: string, config?: AxiosRequestConfig): Promise<T> {
    try {
      const response = await axios.delete<T>(this.url(routePath), this.getConfig(config));
      return response.data;
    } catch (err) {
      throw this.parseError(err);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected override parseApplicationError(status: number | undefined, statusText: string | undefined, data: any, error: any): BaseApplicationError {
    if (data && data.type) {
      switch (data.type) {
        case ErrorTypeEnum.FormError:
          return new FormError(data.payload);
        case ErrorTypeEnum.ErrorMessageError:
          return new ErrorMessageError(data.payload);
        case ErrorTypeEnum.InfoMessageError:
          return new InfoMessageError(data.payload);
        case ErrorTypeEnum.OAuthError:
          return new OAuthError(data.code, data.email, data.message);
      }
    }

    return super.parseApplicationError(status, statusText, data, error);
  }
}

export default BaseService;
