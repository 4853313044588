import type { IEntitiesQueryMapper } from '../../../common/helpers/mappers/interfaces/IEntitiesQueryMapper';
import type { BaseEntityListItemApiModel } from '../../../common/models/api/base/BaseEntityListItemApiModel';
import type { BaseUsersEntityCreateApiModel } from '../../../common/models/api/base/user/BaseUsersEntityCreateApiModel';
import type { BaseUsersEntityDetailApiModel } from '../../../common/models/api/base/user/BaseUsersEntityDetailApiModel';
import type { CreateUsersEntityAnonymouslyApiModel } from '../../../common/models/api/CreateUsersEntityAnonymouslyApiModel';
import type { EntityCreatedByAnonymousUserModel } from '../../../common/models/entities/shared/EntityCreatedByAnonymousUserModel';
import type { BaseEntitiesQuery } from '../../../common/models/queries/base/BaseEntitiesQuery';
import type { BaseEntitiesQueryFilters } from '../../../common/models/queries/base/BaseEntitiesQueryFilters';
import type { IBaseUsersEntitiesService } from '../interface/IBaseUsersEntitiesService';
import BaseEntitiesSearchService from './BaseEntitiesSearchService';

abstract class BaseUsersEntitiesService<
  TCreateModel extends BaseUsersEntityCreateApiModel,
  TDetailModel extends BaseUsersEntityDetailApiModel,
  TQueryFilters extends BaseEntitiesQueryFilters,
  TQuery extends BaseEntitiesQuery<TQueryFilters>,
  TMapper extends IEntitiesQueryMapper<TQuery>,
  TListItemModel extends BaseEntityListItemApiModel
> extends BaseEntitiesSearchService<
  TDetailModel,
  TQueryFilters,
  TQuery,
  TMapper,
  TListItemModel
> implements IBaseUsersEntitiesService<
  TCreateModel,
  TDetailModel
> {

  public createAnonymous = async (data: CreateUsersEntityAnonymouslyApiModel<TCreateModel>): Promise<EntityCreatedByAnonymousUserModel<TDetailModel>> => {
    return this.sendPost<EntityCreatedByAnonymousUserModel<TDetailModel>, CreateUsersEntityAnonymouslyApiModel<TCreateModel>>('/anonymous', data);
  };
}

export default BaseUsersEntitiesService;
