import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import UserRoleEnum from '../constants/UserRoleEnum';
import type { ICommonAwareState } from '../store/modules/app/models/ICommonAwareState';
import useUser from './useUser';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const gtag: any;

const useGoogleAnalytics = (): void => {

  const googleAnalyticsMeasurementId = useSelector((state: ICommonAwareState) => state.common.googleAnalyticsMeasurementId);

  const { pathname, search } = useLocation();

  const user = useUser();

  const pagePath = `${pathname}${search}`;

  useEffect(() => {
    if (googleAnalyticsMeasurementId) {
      if (typeof gtag === 'function') {
        gtag('config', googleAnalyticsMeasurementId, { 'send_page_view': false });
      }
    }
  }, [googleAnalyticsMeasurementId, user]);

  useEffect(() => {
    if (googleAnalyticsMeasurementId && (!user || !user.roles?.includes(UserRoleEnum.Admin))) {
      if (typeof gtag === 'function') {
        gtag('set', 'page_path', pagePath);
        gtag('event', 'page_view');
      }
    }
  }, [googleAnalyticsMeasurementId, pagePath, user]);
};

export default useGoogleAnalytics;
