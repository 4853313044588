class ApiRoutePaths {
  static ADMIN_PART_ADVERTS = '/items';
  static ADMIN_PART_CAROUSEL_ITEMS = '/carousel-items';
  static ADVERTS = '/items';
  static CAROUSEL_ITEMS = '/carousel-items';
  static HOME = '/home';
  static IMAGES_PART_ADVERT = '/item';
  static IMAGES_PART_CAROUSEL_ITEM = '/carousel-item';
  static PROFILE_PART_ADVERTS = '/items';
  static PROFILE_PART_CAROUSEL_ITEMS = '/carousel-items';
}

export default ApiRoutePaths;
