class BaseApiRoutePaths {
  static AUTH_CONTACT = '/contact';
  static AUTH_RESET_PASSWORD = '/reset-password';
  static AUTH_RETRIEVE = '/retrieve';
  static AUTH_SEND_RESET_PASSWORD_LINK = '/send-reset-password-link';
  static AUTH_SIGNIN = '/signin';
  static AUTH_SIGNIN_GOOGLE = '/signup-google';
  static AUTH_SIGNOUT = '/signout';
  static AUTH_SIGNUP = '/signup';
  static IMAGES = '/images';
  static UPLOAD = '/upload';
  static UPLOAD_TMP = '/tmp';
  static VALIDATION = '/validation';
  static VALIDATION_IS_EMAIL_TAKEN = '/is-email-taken';
}

export default BaseApiRoutePaths;
