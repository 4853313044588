import EntityStateActionTypeEnum from '../../../../../constants/enums/EntityStateActionTypeEnum';
import type { BaseEntityDetailApiModel } from '../../../../../models/api/base/BaseEntityDetailApiModel';
import type { FSAP } from '../../../base/fsa';
import type { BaseSucceededPayload } from '../../../models/payloads/base/BaseSucceededPayload';
import createEntityDetailActionName from './createEntityDetailActionName';

const createEntityDetailPopulateAction = <TModel extends BaseEntityDetailApiModel>(entityType: string, data: TModel, namespace: string): FSAP<BaseSucceededPayload<TModel>> => ({
  type: createEntityDetailActionName(entityType, EntityStateActionTypeEnum.Succeeded, namespace),
  payload: {
    data,
  },
});

export default createEntityDetailPopulateAction;
