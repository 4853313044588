import type EntityStateActionTypeEnum from '../../../../../constants/enums/EntityStateActionTypeEnum';

type Mode = 'detail' | 'list';

const createEntityActionNameByMode = (
  mode: Mode,
  entityType: string, // EntityTypeEnum,
  entityStateActionType: EntityStateActionTypeEnum,
  namespace?: string
): string => {
  return `${namespace ? `${namespace}_` : ''}ENTITY_${mode.toUpperCase()}_${entityType.toString().toUpperCase()}_${entityStateActionType}`;
};

export default createEntityActionNameByMode;
